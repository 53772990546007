import axios from 'axios';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { ChangeEventHandler, FC, useEffect, useMemo, useState } from 'react';
import { CSVLink } from 'react-csv';
import Card from '../components/custom/Card';
import CollapseCard from '../components/custom/CollapseCard';
import PageContainer from '../components/custom/PageContainer';
import imgPath from './img/collegeLogo.png';
import './styles/tableReport.css';
// import imgProfile from 'http://localhost/programs/exam-app.png';
import { RowInput } from 'jspdf-autotable';
import * as Feather from 'react-feather';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Option from '../components/Option';
import FormInput from '../components/custom/FormInput';
import { apiUrl } from '../config';
import { YearOptions } from '../config/functions';
import { CourseType } from '../redux/actions/courseActions';
import { DegreeType } from '../redux/actions/degreeAction';
import { DisciplineType } from '../redux/actions/disciplineAction';
import { DisciplineCourseType } from '../redux/actions/disciplineCourseAction';
import { DropDownType } from '../redux/actions/dropDownAction';
import { ExamType } from '../redux/actions/examAction';
import { StoreState } from '../redux/reducers';

interface Props {
    title: string;
}

const AttendanceSheetReport: FC<Props> = (props: Props) => {

    const { title } = props;
    const fileName = "attendance_sheet_report";
    const [studentList, setStudentList] = useState<Student[]>([]);

    // console.log();

    const [selectedBatch, setSelectedBatch] = useState<string>('');

    const dropDownList = useSelector<StoreState, DropDownType[]>(state => state.dropDown)
    const DegreeList = useSelector<StoreState, DegreeType[]>(state => state.degree);
    const CourseList = useSelector<StoreState, CourseType[]>(state => state.course);
    const DisciplineList = useSelector<StoreState, DisciplineType[]>(state => state.discipline);
    const disciplineCourseList = useSelector<StoreState, DisciplineCourseType[]>(state => state.disciplineCourse)

    const [selectedDegreeId, setSelectedDegreeId] = useState<string | null>(null);
    const [selectedDisciplineId, setSelectedDisciplineId] = useState<string | null>(null);
    const [selectedCourseId, setSelectedCourseId] = useState<string>('');
    const [selectedCourseCode, setSelectedCourseCode] = useState<string>('');
    const [selectedCourseName, setSelectedCourseName] = useState<string>('');

    const selectedExam = useSelector<StoreState, ExamType[]>(state => state.exam);
    const [selectedTitle, setSelectedTitle] = useState<string>('');
    const [session, setSession] = useState<string>('');
    const [courseType, setCourseType] = useState<string>('');
    const [courseTypeError, setCourseTypeError] = useState<string>('');

    const yearList = YearOptions();

    const [date, setDate] = useState('');

    const handleDateChange = (e: any) => {
        setDate(e.target.value);
    };

    // Format the date as "DD-MM-YYYY"
    const formattedDate = date
        ? new Date(date).toLocaleDateString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        }).split('/').join('-')
        : '';


    const handleCourse: ChangeEventHandler<HTMLSelectElement> = (e) => {
        let cid = e.target.value
        setSelectedCourseId(cid)
        console.log(cid);

        let obj = CourseList.find(f => f.id!.toString() === cid.toString())
        setSelectedCourseCode(obj?.shortName ? obj?.shortName : '')
        setSelectedCourseName(obj?.name ? obj?.name : '')
    }

    // useEffect(() => {
    //     let disCourseObj = disciplineCourseList.find(f => f.degreeId?.toString() === selectedDegreeId && f.disciplineId?.toString() === selectedDisciplineId && f.courseId?.toString() === selectedCourseId)

    //     setCourseType(disCourseObj?.courseType ? disCourseObj?.courseType : '')
    // }, [disciplineCourseList, selectedDegreeId, selectedDisciplineId, selectedCourseId])

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = () => {
        axios.get<{ data: Student[] }>(`${apiUrl}/student/`)
            .then((response) => {
                console.log(response);

                setStudentList(response.data.data);
            })
            .catch((error) => console.log(error));
    }

    // const data = [
    //     {
    //         "S.No": "1",
    //         "code": "123",
    //         "collegeName": "Parisutham Institute of Technology and Science",
    //         "Degree": "B.Tech",
    //         "Discipline": "Electrical Engineering",
    //         "courseCode": "789",
    //         "courseTitle": "Mechanics",
    //         "DOE": "05/15/2023",
    //         "session": "FN",
    //         "registerNumber": "821322104001",
    //         "candidate": "Jane Doe Jane Doe Jane Doe",
    //         "photo": "sam",
    //         "exam": "Nov/Dec",
    //         "Year": "2023",
    //     },
    // ]

    const getUniqueBatch = () => {
        const uniqueBatch = Array.from(new Set(studentList.map((batch) => batch.batch)));
        return uniqueBatch;
    };

    const [filteredStudentList, setFilteredStudentList] = useState<Student[]>([]);

    console.log(filteredStudentList);


    const filterCourseList = () => {
        if (courseType === 'Theory' || courseType === 'theory' || courseType.toLowerCase() === 'theory cum practical') {

            if (selectedDegreeId !== '' && selectedDisciplineId !== '' && selectedBatch !== '' && selectedTitle !== '' && session !== '' && date !== '' && selectedCourseId !== '') {

                let examId = selectedExam.find((e) => e.title === selectedTitle)?.id
                let disId = disciplineCourseList.find((dc) => dc.disciplineId === parseInt(selectedDisciplineId || "") && dc.courseId === parseInt(selectedCourseId) && dc.degreeId === parseInt(selectedDegreeId || "") && dc.courseType === courseType)?.id;

                axios.get<{ data: Student[] }>(`${apiUrl}/student/selectStuDetailForAttendance/?examId=${examId}&degreeId=${selectedDegreeId}&disciplineId=${selectedDisciplineId}&disciplineCourseId=${disId}&batch=${selectedBatch}`)
                    .then((response) => {

                        setFilteredStudentList(response.data.data);
                    })
                    .catch((error) => console.log(error));
            } else {
                toast.error('Select All Filters', { position: 'top-right', autoClose: 3000 });
                setFilteredStudentList([])
            }
        } else if (courseType === 'Practical' || courseType === 'practical') {
            if (selectedDegreeId !== '' && selectedDisciplineId !== '' && selectedBatch !== '' && selectedTitle !== '' && selectedCourseId !== '') {

                let examId = selectedExam.find((e) => e.title === selectedTitle)?.id
                let disId = disciplineCourseList.find((dc) => dc.disciplineId === parseInt(selectedDisciplineId || "") && dc.courseId === parseInt(selectedCourseId) && dc.degreeId === parseInt(selectedDegreeId || "") && dc.courseType === courseType)?.id;

                axios.get<{ data: Student[] }>(`${apiUrl}/student/selectStuDetailForAttendance/?examId=${examId}&degreeId=${selectedDegreeId}&disciplineId=${selectedDisciplineId}&disciplineCourseId=${disId}&batch=${selectedBatch}`)
                    .then((response) => {
                        setFilteredStudentList(response.data.data);
                    })
                    .catch((error) => console.log(error));
            } else {
                toast.error('Select All Filters', { position: 'top-right', autoClose: 3000 });
                setFilteredStudentList([])
            }
        }
    };

    console.log(courseType);
    // const [perPageCount,setPerPageCount] = useState(25);
    // const numOfItems = filteredStudentList.length/25;
    // const [index, setIndex] = useState(0);


    // useEffect(()=>{
    //     for(let i =0; i<Math.ceil(numOfItems); i++);
    //     {
    //         setPerPageCount(perPageCount+25);
    //         setIndex(index+25);
    //     }
    // },[numOfItems])


    // console.log(perPageCount);
    // console.log(index);
    const numOfItems = filteredStudentList.length / 25;

    const itemsPerpage = useMemo(() => {
        let ind = 0;
        let pageCount = 25;
        const items = [];
        for (let i = 0; i < Math.ceil(numOfItems); i++) {
            items.push({ index: ind, pageCount: pageCount });
            ind = pageCount;
            pageCount = pageCount + 25;
        }
        return items;
    }, [filteredStudentList])
    console.log(courseType);


    const handleDownloadPDF = () => {
        // const [perPageCount, setPerPageCount] = useState(25);
        // const numOfItems = filteredStudentList.length / 25;
        // const [index, setIndex] = useState(0);

        const doc = new jsPDF('p', 'mm', 'a4');

        let i = 0
        itemsPerpage.map((ip) => {


            // for (let i = 0; i < Math.ceil(numOfItems); i++) {

            // setPerPageCount(perPageCount + 25);
            // setIndex(index + 25);

            if (courseType === 'Theory' || courseType === 'theory' || courseType.toLowerCase() === 'theory cum practical') {
                const recordLength = filteredStudentList.length / 25
                // for (let i = 0; i < recordLength; i++) {
                //     let startLimit = 25 * i
                doc.addImage(imgPath, 'PNG', 25, 5, 170, 30);

                doc.setFontSize(14);
                doc.setFont('Arial', 'normal', 'bold');
                doc.setTextColor(0, 0, 0);
                doc.text(title, 107, 34, { align: "center" });

                doc.rect(7, 7, 195, 285);

                const selectedDegreeName = DegreeList?.find((degree) => degree.id === (selectedDegreeId ? parseInt(selectedDegreeId) : null))?.name || 'N/A';
                const selectedDisciplineName = DisciplineList?.find((discipline) => discipline.id === (selectedDisciplineId ? parseInt(selectedDisciplineId) : null))?.name || 'N/A';
                doc.setFontSize(12);

                const firstTable = autoTable(doc, {
                    didDrawPage: (data) => {
                        doc.setFontSize(10);
                        doc.setFont('normal');
                        doc.text(`${selectedTitle}`, data.settings.margin.left + 90, 38, { align: 'center' });
                    },
                    startY: 39,
                    headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], fontSize: 9, halign: 'left' },
                    bodyStyles: { textColor: [0, 0, 0] },
                    head: [
                        [
                            // { content: `College Code & Name: 8213, PARISUTHAM INSTITUTE OF TECHNOLOGY AND SCIENCE `},
                            // { content: `Date of Examination: ${date}`},
                            { content: `Degree & Discipline: ${selectedDegreeName} - ${selectedDisciplineName}` },
                            { content: `Date of Examination: ${formattedDate}` },
                            // { content: `Course Code & Name:  ${selectedCourseCode} – ${selectedCourseName}` }
                        ],
                        [
                            // { content: `Degree & Discipline: ${selectedDegreeId} ${selectedDisciplineId}`},
                            { content: `Course Code & Name:  ${selectedCourseCode} – ${selectedCourseName}` },
                            { content: `Session: ${session}` },
                        ],
                        [
                            // {content: `Course Code & Name:  ${selectedCourseCode} – ${selectedCourseName}`}
                        ]
                    ],
                    theme: 'plain',
                });
                //2nd Table
                autoTable(doc, {
                    startY: (doc as any).lastAutoTable.finalY - 8,
                    margin: { left: 8 },
                    tableWidth: 193,
                    headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], lineWidth: 0.3, lineColor: [0, 0, 0], fontSize: 8, halign: 'center' },
                    bodyStyles: { textColor: [0, 0, 0], lineColor: [0, 0, 0], fontSize: 8 },
                    head: [
                        [
                            { content: 'S.No.' },
                            { content: 'Register Number' },
                            { content: 'Name of the Candidate' },
                            { content: 'Answer Booklet No.', colSpan: 8 },
                            { content: 'AB*' },
                            { content: 'Signature of the Candidate' },
                            // { content: 'Photo' },
                        ]
                    ],
                    theme: 'grid',
                    body: filteredStudentList.slice(ip.index, ip.pageCount).map((row, index) => {
                        i = i + 1;
                        return [
                            { content: i, styles: { halign: 'center' } },
                            { content: row.registerNo, styles: { halign: 'center' } },
                            { content: row.name, styles: { halign: 'left' } },
                            { content: "", styles: { halign: 'center', cellWidth: 6 } },
                            { content: "", styles: { halign: 'center', cellWidth: 6 } },
                            { content: "", styles: { halign: 'center', cellWidth: 6 } },
                            { content: "", styles: { halign: 'center', cellWidth: 6 } },
                            { content: "", styles: { halign: 'center', cellWidth: 6 } },
                            { content: "", styles: { halign: 'center', cellWidth: 6 } },
                            { content: "", styles: { halign: 'center', cellWidth: 6 } },
                            { content: "", styles: { halign: 'center', cellWidth: 6 } },
                            { content: "", styles: { halign: 'center' } },
                            // { content: "", styles: { halign: 'center' } },
                            //     {
                            //     didDrawPage: (data: any) => {
                            //          doc.addImage(imgProfile, 'PNG', 25, 15, 10, 10).toString()
                            //     }       
                            //    }

                        ]
                    }
                    ),

                });

                doc.setFontSize(8);
                doc.text('*Hall Superintendent should mark ‘AB’ for Absent', 10, (doc as any).lastAutoTable.finalY + 3)

                const additionalRowTopPart: RowInput[] = [
                    [
                        { content: 'TOTAL PRESENT :', styles: { fontStyle: 'bold', halign: 'left', lineWidth: 0.3, lineColor: [0, 0, 0], cellWidth: 100, } },
                        { content: 'TOTAL ABSENT :', styles: { fontStyle: 'bold', halign: 'left', lineWidth: 0.3, lineColor: [0, 0, 0], cellWidth: 90 } },
                    ],
                    [
                        { content: 'Certified that the following particulars have been Verified:', styles: { cellPadding: { top: 2 } } },
                        // { content: '*Hall Superintendent should mark ‘AB’ for Absent ', styles: { cellPadding: { left: 5, top: 6 } } },
                    ],
                ];
                // Additional Rows
                const additionalRowsmiddlePart: RowInput[] = [
                    // [
                    //     { content: 'TOTAL PRESENT :', styles: { fontStyle: 'bold', halign: 'left', lineWidth: 0.3, lineColor: [0, 0, 0], cellWidth: 100, } },
                    //     { content: 'TOTAL ABSENT :', styles: { fontStyle: 'bold', halign: 'left', lineWidth: 0.3, lineColor: [0, 0, 0], cellWidth: 90 } },
                    // ],
                    // [
                    //     { content: 'Certified that the following particulars have been Verified:', styles: { cellPadding: { top: 6 } } },
                    //     // { content: '*Hall Superintendent should mark ‘AB’ for Absent ', styles: { cellPadding: { left: 5, top: 6 } } },
                    // ],
                    [
                        { content: '1. ', styles: { cellWidth: 10, cellPadding: { top: 1, left: 3.5 } } },
                        { content: 'The Register No. in the attendance sheet with that in the hall ticket.', styles: { cellPadding: { top: 1 }, fontSize: 8 } },
                    ],
                    [
                        { content: '2. ', styles: { cellWidth: 10, cellPadding: { left: 3.5 } } },
                        { content: 'The identification of the candidate with the photo given in the hall ticket.', styles: { cellPadding: {}, fontSize: 8 } },
                    ],
                    [
                        { content: '3. ', styles: { cellWidth: 10, cellPadding: { left: 3.5 } } },
                        { content: 'The answer booklet number entered in the attendance sheet by the candidate with the Serial No. on the Answer Booklet.', styles: { cellPadding: { right: 12 }, fontSize: 8 } },
                    ],
                    // [
                    //     { content: 'Signature of the Hall Superintendent with Name and Designation', styles: { cellPadding: { top: 30, left: 2 }, fontSize: 8 } },
                    //     { content: 'Signature of the Chief Superintendent with Name and Designation', styles: { cellPadding: { top: 30, right: 2 }, fontSize: 8 } },
                    // ],
                ];
                const additionalRowLastPart: RowInput[] = [
                    [
                        { content: 'Signature of the Hall Superintendent with Name and Designation', styles: { cellPadding: { top: 10, left: 2 }, fontSize: 8 } },
                        { content: 'Signature of the Chief Superintendent with Name and Designation', styles: { cellPadding: { top: 10, right: 2 }, fontSize: 8 } },
                    ],
                ];
                //bottom table part-1(total)
                autoTable(doc, {
                    startY: (doc as any).lastAutoTable.finalY + 5,
                    margin: { left: 10 },
                    tableWidth: 193,
                    headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], fontSize: 8 },
                    bodyStyles: { textColor: [0, 0, 0], fontStyle: 'bold', fontSize: 9 },
                    body: additionalRowTopPart,
                    theme: 'plain',

                });
                //bottom table part-2(text)
                autoTable(doc, {
                    startY: (doc as any).lastAutoTable.finalY - 1,
                    margin: { left: 10 },
                    tableWidth: 193,
                    headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], fontSize: 8 },
                    bodyStyles: { textColor: [0, 0, 0], fontStyle: 'bold', fontSize: 9 },
                    body: additionalRowsmiddlePart,
                    theme: 'plain',

                });
                //bottom table part-3(signature)
                autoTable(doc, {
                    startY: (doc as any).lastAutoTable.finalY + 10,
                    // margin: { left: 10 },
                    tableWidth: 193,
                    headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0] },
                    bodyStyles: { textColor: [0, 0, 0], fontStyle: 'bold' },
                    body: additionalRowLastPart,
                    theme: 'plain'

                });
                // let indexValue = index + 25;
                // let perPageNum = perPageCount + 25;
                // doc.save(fileName + '.pdf');

            } else if (courseType === 'Practical' || courseType === 'practical') {
                const recordLength = filteredStudentList.length / 25
                // for (let i = 0; i < recordLength; i++) {
                let startLimit = 25 * i
                let endLimit = 25 * i + 25
                if (i > 0) {
                    doc.addPage(); // Add a new page for each hall report except the first one
                }
                doc.addImage(imgPath, 'PNG', 25, 6, 170, 30);
                doc.setFontSize(12);
                doc.setFont('Arial', 'normal', 'bold');
                doc.setTextColor(0, 0, 0);
                doc.text(title, 107, 35, { align: 'center' });

                doc.rect(7, 7, 195, 285);

                const selectedDegreeName = DegreeList.find((degree) => degree.id === (selectedDegreeId ? parseInt(selectedDegreeId) : null))?.name || 'N/A';
                const selectedDisciplineName = DisciplineList.find((discipline) => discipline.id === (selectedDisciplineId ? parseInt(selectedDisciplineId) : null))?.name || 'N/A';
                doc.setFontSize(12);

                const firstTable = autoTable(doc, {
                    didDrawPage: (data) => {
                        doc.setFontSize(10);
                        doc.setFont('normal');
                        doc.text(`${selectedTitle}`, data.settings.margin.left + 71, 40);
                    },
                    startY: 42,
                    headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], fontSize: 8, halign: 'left' },
                    bodyStyles: { textColor: [0, 0, 0] },
                    head: [
                        [
                            { content: `Degree & Discipline: ${selectedDegreeName} - ${selectedDisciplineName}` },
                            { content: `Date of Examination: ${formattedDate}` },
                        ],
                        [
                            { content: `Course Code & Name:  ${selectedCourseCode} – ${selectedCourseName}` },
                            { content: `Session: ${session}` },
                        ],
                    ],
                    theme: 'plain',
                });
                //2nd Table
                autoTable(doc, {
                    startY: (doc as any).lastAutoTable.finalY,
                    margin: { left: 8 },
                    tableWidth: 193,
                    headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], lineWidth: 0.3, lineColor: [0, 0, 0], fontSize: 8, halign: 'center' },
                    bodyStyles: { textColor: [0, 0, 0], lineColor: [0, 0, 0], fontSize: 8 },
                    head: [
                        [
                            { content: 'S.No.' },
                            { content: 'Register Number' },
                            { content: 'Name of the Candidate' },
                            { content: 'Answer Booklet No.', colSpan: 8 },
                            { content: 'AB*' },
                            { content: 'Signature of the Candidate' },
                            // { content: 'Photo' },
                        ]
                    ],
                    theme: 'grid',
                    body: filteredStudentList?.slice(ip.index, ip.pageCount).map((row, index) => {
                        i = i + 1;
                        return [
                            { content: i, styles: { halign: 'center' } },
                            { content: row.registerNo, styles: { halign: 'center' } },
                            { content: row.name, styles: { halign: 'left' } },
                            { content: "", styles: { halign: 'center', cellWidth: 6 } },
                            { content: "", styles: { halign: 'center', cellWidth: 6 } },
                            { content: "", styles: { halign: 'center', cellWidth: 6 } },
                            { content: "", styles: { halign: 'center', cellWidth: 6 } },
                            { content: "", styles: { halign: 'center', cellWidth: 6 } },
                            { content: "", styles: { halign: 'center', cellWidth: 6 } },
                            { content: "", styles: { halign: 'center', cellWidth: 6 } },
                            { content: "", styles: { halign: 'center', cellWidth: 6 } },
                            { content: "", styles: { halign: 'center' } },
                        ]
                    }),
                });

                doc.setFontSize(8);
                doc.text('*Hall Superintendent should mark ‘AB’ for Absent', 10, (doc as any).lastAutoTable.finalY + 3)

                const additionalRowTopPart: RowInput[] = [
                    [
                        { content: 'TOTAL PRESENT :', styles: { fontStyle: 'bold', halign: 'left', lineWidth: 0.3, lineColor: [0, 0, 0], cellWidth: 100, } },
                        { content: 'TOTAL ABSENT :', styles: { fontStyle: 'bold', halign: 'left', lineWidth: 0.3, lineColor: [0, 0, 0], cellWidth: 90 } },
                    ],
                    [
                        { content: 'Certified that the following particulars have been Verified:', styles: { cellPadding: { top: 2.5 } } },
                    ],
                ];
                // Additional Rows
                const additionalRowsmiddlePart: RowInput[] = [
                    [
                        { content: '1. ', styles: { cellWidth: 10, cellPadding: { top: 1, left: 3.5 } } },
                        { content: 'The Register No. in the attendance sheet with that in the hall ticket.', styles: { cellPadding: { top: 1 } } },
                    ],
                    [
                        { content: '2. ', styles: { cellWidth: 10, cellPadding: { left: 3.5 } } },
                        { content: 'The identification of the candidate with the photo given in the hall ticket.', styles: { cellPadding: {} } },
                    ],
                    [
                        { content: '3. ', styles: { cellWidth: 10, cellPadding: { left: 3.5 } } },
                        { content: 'The answer booklet number entered in the attendance sheet by the candidate with the Serial No. on the Answer Booklet.', styles: { cellPadding: { right: 12 } } },
                    ],
                ];

                const additionalRowLastPart: RowInput[] = [
                    [
                        { content: 'INTERNAL EXAMINER', styles: { halign: 'left', cellPadding: { top: 8, left: 10 }, fontSize: 8 } },
                        { content: 'EXTERNAL EXAMINER', styles: { halign: 'right', cellPadding: { top: 8, right: 10 }, fontSize: 8 } },
                    ],
                ];
                //bottom table part-1(total)
                autoTable(doc, {
                    startY: (doc as any).lastAutoTable.finalY + 5,
                    margin: { left: 10 },
                    tableWidth: 193,
                    headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], fontSize: 8 },
                    bodyStyles: { textColor: [0, 0, 0], fontStyle: 'bold', fontSize: 8 },
                    body: additionalRowTopPart,
                    theme: 'plain',

                });
                //bottom table part-2(text)
                autoTable(doc, {
                    startY: (doc as any).lastAutoTable.finalY - 1,
                    margin: { left: 10 },
                    tableWidth: 193,
                    headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], fontSize: 8 },
                    bodyStyles: { textColor: [0, 0, 0], fontStyle: 'bold', fontSize: 8 },
                    body: additionalRowsmiddlePart,
                    theme: 'plain',

                });
                //bottom table part-3(signature)
                autoTable(doc, {
                    startY: (doc as any).lastAutoTable.finalY + 4,
                    margin: { left: 10 },
                    tableWidth: 193,
                    headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], fontSize: 8 },
                    bodyStyles: { textColor: [0, 0, 0], fontStyle: 'bold', fontSize: 8 },
                    body: additionalRowLastPart,
                    theme: 'plain',

                });


            }
            doc.addPage()

        });
        doc.save(fileName + '.pdf');
    };

    const csvData = [
        [
            'S.No',
            'Register Number',
            'Name of the Candidate',
        ],
        ...filteredStudentList.map((student, index) => [
            index + 1,
            student.registerNo,
            student.name
        ]),
    ];

    return (
        <>
            <PageContainer title="EXAMINATION ATTENDANCE SHEET">
                <Card title="filters">
                    <div className="row">
                        <div className="col-sm-6 col-md-4 col-lg-3">
                            <label className="mt-2 mb-2" htmlFor="examId">Exam <span className="text-danger">*</span> : </label>
                            <select
                                className="form-control"
                                name="examIdFilter"
                                value={selectedTitle}
                                onChange={(e) => setSelectedTitle(e.target.value)}
                            >
                                <option value="" selected>Select Exam</option>
                                {selectedExam.map((course) => (
                                    <option key={course.title} value={course.title}>
                                        {course.title}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-3">
                            <label className="mt-2 mb-2">Degree <span className="text-danger">*</span> :</label>
                            <select
                                className="form-control"
                                value={selectedDegreeId || ''}
                                onChange={(e) => setSelectedDegreeId(e.target.value)}
                            >
                                <option value="">Select Degrees</option>
                                {DegreeList.map((course) => (
                                    <option key={course.id} value={course.id}>
                                        {course.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-3">
                            <label className="mt-2 mb-2">Discipline <span className="text-danger">*</span> :</label>
                            <select
                                className="form-control"
                                value={selectedDisciplineId || ''}
                                onChange={(e) => setSelectedDisciplineId(e.target.value)}
                            >
                                <option value="">Select Disciplines</option>
                                {DisciplineList.map((course) => (
                                    <option key={course.id} value={course.id}>
                                        {course.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-3">
                            <FormInput
                                name="Batch"
                                label="Batch"
                                labelClassName="required"
                                value={selectedBatch}
                                onChange={(e) => setSelectedBatch(e.target.value)}
                                placeholder="Select Batch"
                                containerClass="mt-2"
                                type="select"
                            >
                                <option value="">Select Batch</option>
                                {yearList.map((y, i) => {
                                    return <Option key={i} value={y}>{y}</Option>
                                })
                                }
                            </FormInput>
                        </div>
                        {/* <div className="col-sm-6 col-md-4 col-lg-3">
                            <label className="mt-2 mb-2">Select Batch:</label>
                            <select
                                className="form-control"
                                value={selectedBatch}
                                onChange={(e) => setSelectedBatch(e.target.value)}
                            >
                                <option value="" selected>Select Batch</option>
                                {getUniqueBatch().map((batch) => (
                                    <option key={batch} value={batch}>
                                        {batch}
                                    </option>
                                ))}
                            </select>
                        </div> */}

                        {/* <div className="col-sm-6 col-md-4 col-lg-3">
                            <label className="mt-2 mb-2">Select Course Code:</label>
                            <select
                                className="form-control"
                                value={selectedCourseCode || ''}
                                onChange={(e) => setSelectedCourseCode(e.target.value)}
                            >
                                <option value="">All Course Code</option>
                                {selectedCourse.map((course) => (
                                    <option key={course.shortName} value={course.shortName}>
                                        {course.shortName}
                                    </option>
                                ))}
                            </select>
                        </div> */}
                        <div className="col-sm-6 col-md-4 col-lg-3">
                            <label className="mt-2 mb-2">Course Name <span className="text-danger">*</span> :</label>
                            <select
                                className="form-control"
                                value={selectedCourseId}
                                onChange={handleCourse}
                            >
                                <option value="">Select Course Name</option>
                                {CourseList?.map((course) => (
                                    <option key={course.id} value={course.id}>
                                        {course.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-3">
                            <label className="mt-2 mb-2">Course Type <span className="text-danger">*</span> : </label>
                            <select
                                className="form-control"
                                name="CourseHead"
                                onChange={(e) => {
                                    setCourseType(e.target.value);
                                    setCourseTypeError('');
                                }}
                                value={courseType}
                            >
                                <option value="">Select Course Type</option>
                                {dropDownList?.filter(dl => dl.category === 'Course Type')?.map((dd) => {
                                    return <option value={dd.title} key={dd.id}>{dd.title}</option>
                                })
                                }

                            </select>
                            {courseTypeError && <div className="text-danger">{courseTypeError}</div>}
                        </div>

                        <div className="col-sm-6 col-md-4 col-lg-3">
                            <label className="mt-2 mb-2">Session :</label>
                            <select
                                className="form-control"
                                value={session || ''}
                                onChange={(e) => setSession(e.target.value)}
                            >
                                <option value="">Select Session</option>
                                <option value="AN">AN</option>
                                <option value="FN">FN</option>
                            </select>
                        </div>

                        <div className="col-sm-6 col-md-4 col-lg-3">
                            <label className='my-2'>Date :</label>
                            <input
                                type="date"
                                id="fromDate"
                                value={date}
                                onChange={handleDateChange}

                                className="form-control "
                            />
                        </div>
                    </div>

                    <div className="col-12">
                        <button type="button" className="btn btn-sm btn-primary mt-3" onClick={filterCourseList} style={{ width: "10%", marginTop: "55px", float: "right" }}>Search
                        </button>
                    </div>
                </Card>
                <CollapseCard title="Exam Attendance Sheet Report">
                    <div className="container-fluid table-container" >
                        <table className="table table-success table-striped">
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Register Number</th>
                                    <th>Name of the Candidate</th>
                                    {/* <th>Degree</th>
                    <th>Discipline</th>
                    <th>Photo</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {filteredStudentList?.map((student, index) => (
                                    <tr key={student.id}>
                                        <td>{index + 1}</td>
                                        <td>{student.registerNo}</td>
                                        <td>{student.name}</td>
                                        {/* <td><img src="" alt={student.name} className="img-thumbnail w-100px" width={100} height={100} /></td> */}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="text-end mb-3">
                        {filteredStudentList?.length > 0 && <button
                            type="button"
                            onClick={handleDownloadPDF}
                            className="m-1 btn btn-sm btn-primary"
                        >
                            <Feather.Download /> PDF
                        </button>}
                        {filteredStudentList?.length > 0 && <CSVLink
                            data={csvData}
                            filename="details_of_course.csv"
                            className="m-1 btn btn-sm btn-primary"
                        >
                            <Feather.Download /> CSV
                        </CSVLink>}
                    </div>
                </CollapseCard>
            </PageContainer>
        </>
    );
}

export default AttendanceSheetReport

interface Student {
    id: number;
    registerNo: string;
    regulation: number;
    departmentRegisterNumber: string;
    name: string;
    fatherName: string;
    motherName: string;
    guardianName: string;
    bloodGroup: string;
    gender: string;
    dateOfBirth: string;
    aadharNumber: string;
    address: string;
    pincode: number;
    emailId: string;
    contactNumber: string;
    parentContactNumber: number;
    community: string;
    caste: string;
    religion: string;
    state: string;
    nationality: string;
    motherTongue: string;
    degreeId: number;
    disciplineId: number;
    modeOfAdmission: string;
    batch: string;
    entryLevel: string;
    dateOfJoin: string;
    noOfYears: number;
    currentSemester: number;
    photoUrl: string;
    createdBy: string;
    createTimestamp: string;
}