import axios from "axios"
import jsPDF from "jspdf"
import autoTable from "jspdf-autotable"
import { useEffect, useState } from "react"
import { Button, Modal } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { toast } from "react-toastify"
import Card from "../components/custom/Card"
import CollapseCard from "../components/custom/CollapseCard"
import PageContainer from "../components/custom/PageContainer"
import { apiUrl } from "../config"
import api, { getAxiosRequestConfig } from "../config/api"
import { LocalFormatDate } from "../config/functions"
import { AuthUserType } from "../redux/actions/authUserActions"
import { CourseType } from "../redux/actions/courseActions"
import { DisciplineCourseType } from "../redux/actions/disciplineCourseAction"
import { ExamType } from "../redux/actions/examAction"
import { ExamFacultyType } from "../redux/actions/examFacultyAction"
import { FacultyType } from "../redux/actions/facultyActions"
import { StudentMarkType, addStudentMarkAction, deleteStudentMarkAction, fetchStudentMarkAction } from "../redux/actions/studentMarkAction"
import { StoreState } from "../redux/reducers"
import { AppDispatch } from "../redux/store"
import imgPath from './img/collegeLogo.png'
import { MarkSheetReportType } from "./MarkSheetReport"
import { inWords } from "./PracticalMarkReport"
import { StudentGrade } from "./StudentMarkPractical"

export function calculateGrade(totalConversion: number, extConvertedMark: number): number {

    if (extConvertedMark < 27) {//this check is only for theory
        return 0;
    }
    if (totalConversion >= 91 && totalConversion <= 100) {
        return 10;
    } else if (totalConversion >= 81 && totalConversion <= 90) {
        return 9;
    } else if (totalConversion >= 71 && totalConversion <= 80) {
        return 8;
    } else if (totalConversion >= 61 && totalConversion <= 70) {
        return 7;
    } else if (totalConversion >= 56 && totalConversion <= 60) {
        return 6;
    } else if (totalConversion >= 50 && totalConversion <= 55) {
        return 5;
    } else {
        return 0;
    }
}

export function calculateGradeText(totalConversion: number, extConvertedMark: number): string {

    if (extConvertedMark < 27) {//this check is only for theory
        return 'U';
    }
    if (totalConversion >= 91 && totalConversion <= 100) {
        return 'O';
    } else if (totalConversion >= 81 && totalConversion <= 90) {
        return 'A+';
    } else if (totalConversion >= 71 && totalConversion <= 80) {
        return 'A';
    } else if (totalConversion >= 61 && totalConversion <= 70) {
        return 'B+';
    } else if (totalConversion >= 56 && totalConversion <= 60) {
        return 'B';
    } else if (totalConversion >= 50 && totalConversion <= 55) {
        return 'C';
    } else {
        return 'U';
    }
}


export const StudentMark = () => {

    const dispatch = useDispatch<AppDispatch>()
    const authUser = useSelector<StoreState, AuthUserType | null>(state => state.authUser)

    const token = authUser?.token!
    const config = getAxiosRequestConfig(token)

    const examList = useSelector<StoreState, ExamType[]>(state => state.exam)
    const courseList = useSelector<StoreState, CourseType[]>(state => state.course)
    const facultyList = useSelector<StoreState, FacultyType[]>(state => state.faculty);
    const studentMarkList = useSelector<StoreState, StudentMarkType[]>(state => state.studentMark)
    const disciplineCourseList = useSelector<StoreState, DisciplineCourseType[]>(state => state.disciplineCourse)
    const [studentarrearList, setStudentarrearList] = useState<StudentArrearType[]>([]);


    const [editFlag, setEditFlag] = useState(false);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

    const [exam, setExam] = useState<string>('')
    const [examError, setExamError] = useState<string>('')

    const [degree, setDegree] = useState<string>('')
    const [degreeError, setDegreeError] = useState<string>('')

    const [discipline, setDiscipline] = useState<string>('')
    const [disciplineError, setDisciplineError] = useState<string>('')

    const [semester, setSemester] = useState<string>('')
    const [semesterError, setSemesterError] = useState<string>('')

    const [registeNo, setRegisterNo] = useState<string>('')
    const [registeNoError, setRegisterNoError] = useState<string>('')

    const [dummyNo, setDummyNo] = useState<string>('')
    const [dummyNoError, setDummyNoError] = useState<string>('')

    const [disciplineCourse, setDisciplineCourse] = useState<string>('')
    const [disciplineCourseError, setDisciplineCourseError] = useState<string>('')

    const [questionPaper, setQuestionPaper] = useState<string>('')
    const [questionPaperError, setQuestionPaperError] = useState<string>('')

    const [securedMark, setSecuredMark] = useState<string>('')
    const [securedMarkError, setSecuredMarkError] = useState<string>('')

    const [convertedMark, setConvertedMark] = useState<string>('')
    const [convertedMarkError, setConvertedMarkError] = useState<string>('')

    const [avgMark, setAvgMark] = useState<string>('')
    const [avgMarkError, setAvgMarkError] = useState<string>('')

    const [adjuestedMark, setAdjustedMark] = useState<string>('')
    const [adjuestedMarkError, setAdjustedMarkError] = useState<string>('')

    const [intExaminer, setIntExaminer] = useState<string>('')
    const [intExaminerError, setIntExaminerError] = useState<string>('')

    const [extExaminer, setExtExaminer] = useState<string>('')
    const [extExaminerError, setExtExaminerError] = useState<string>('')

    const [chiefExaminer, setChiefExaminer] = useState<string>('')
    const [chiefExaminerError, setChiefExaminerError] = useState<string>('')

    // const [intConversion, setIntConversion] = useState<string[]>([])
    // const [extConverSion, setExtConversion] = useState<string[]>([])

    const [date, setDate] = useState<string>('');
    const [session, setSession] = useState<string>('');

    const [id, setId] = useState<number>(0)
    const [updateId, setUpdateId] = useState<number>(0)

    const [selectedExam, setSelectedExam] = useState<Exam[]>([]);
    const [selectedDegree, setSelectedDegree] = useState<Degree[]>([]);
    const [selectedDiscipline, setSelectedDiscipline] = useState<Discipline[]>([]);
    const [selectedCourse, setSelectedCourse] = useState<Course[]>([]);
    const [selectedDisciplineCourse, setSelectedDisciplineCourse] = useState<DisciplineCourse[]>([]);
    const [convertion, setConvertion] = useState<DisciplineCourse | null>(null);
    const [selectDummyNoList, setSelectedDummyNoList] = useState<DummyNoItem[]>([]);

    //array input update
    const [securedMarks, setSecuredMarks] = useState(Array(selectDummyNoList.length).fill(''));
    const [intExaminers, setIntExaminers] = useState(Array(selectDummyNoList.length).fill(''));
    const [extExaminers, setExtExaminers] = useState(Array(selectDummyNoList.length).fill(''));

    const [dummyNumberFrom, setDummyNumberFrom] = useState('');
    const [dummyNumberTo, setDummyNumberTo] = useState('');
    const [dummyNumberFromError, setDummyNumberFromError] = useState('');
    const [dummyNumberToError, setDummyNumberToError] = useState('');

    const [dataList, setDataList] = useState<DummyNoItem[]>([]);
    const [searchPerformed, setSearchPerformed] = useState(false);

    //filter View 
    const [studentListFilter, setFilteredStudentList] = useState<StudentMarkType[]>([]);
    const [examFilter, setExamFilter] = useState<string>('')
    const [degreeFilter, setDegreeFilter] = useState<string>('')
    const [disciplineFilter, setDisciplineFilter] = useState<string>('')
    const [courseFilter, setCourseFilter] = useState<string>('')
    const [semesterFilter, setSemesterFilter] = useState<string>('')
    const [dummyNumberFromFilter, setDummyNumberFromFilter] = useState('');
    const [dummyNumberToFilter, setDummyNumberToFilter] = useState('');

    const examFacultyList = useSelector<StoreState, ExamFacultyType[]>(state => state.examFaculty);

    const [batchFilter, setBatchFilter] = useState<string>('')

    const [batch, setBatch] = useState<string>('');
    const [batchError, setBatchError] = useState<string>('');
    const [last10Years, setLast10Years] = useState<number[]>([]);
    const [editModalShow, setEditModalShow] = useState(false);


    //edit modal variables
    const [editExam, setEditExam] = useState<string>('')
    const [editDegree, setEditDegree] = useState<string>('')
    const [editDiscipline, setEditDiscipline] = useState<string>('')
    const [editSemester, setEditSemester] = useState<string>('')
    const [editRegisterNo, setEditRegisterNo] = useState<string>('')
    const [editDummyNo, setEditDummyNo] = useState<string>('')
    const [editDisciplineCourse, setEditDisciplineCourse] = useState<string>('')
    const [editQuestionPaperCode, setEditQuestionPaperCode] = useState<string>('')
    const [editSecuredMark, setEditSecuredMark] = useState<string>('')
    const [editSecuredMarkError, setEditSecuredMarkError] = useState<string>('')
    const [editIntMark, setEditIntMark] = useState<string>('0')

    const [editIntExaminer, setEditIntExaminer] = useState<string>('')
    const [editIntExaminerError, setEditIntExaminerError] = useState<string>('')

    const [editExtExaminer, setEditExtExaminer] = useState<string>('')
    const [editExtExaminerError, setEditExtExaminerError] = useState<string>('')

    const [editChiefExaminer, setEditChiefExaminer] = useState<string>('')
    const [editChiefExaminerError, setEditChiefExaminerError] = useState<string>('')

    useEffect(() => {
        const currentYear = new Date().getFullYear();
        const years = Array.from({ length: 10 }, (_, index) => currentYear - index);
        setLast10Years(years);
    }, []);

    useEffect(() => {
        axios.get(`${apiUrl}/exam/`)
            .then((response) => {
                setSelectedExam(response.data.data);
            })
            .catch((error) => {
                console.error('Error fetching degree', error);
            });
    }, [])

    useEffect(() => {
        axios.get(`${apiUrl}/degree/`)
            .then((response) => {
                setSelectedDegree(response.data.data);
            })
            .catch((error) => {
                console.error('Error fetching degree', error);
            });
    }, [])

    useEffect(() => {
        axios.get(`${apiUrl}/discipline/`)
            .then((response) => {
                setSelectedDiscipline(response.data.data);
            })
            .catch((error) => {
                console.error('Error fetching discipline', error);
            });
    }, [])

    useEffect(() => {
        axios.get(`${apiUrl}/course/`)
            .then((response) => {
                setSelectedCourse(response.data.data);
            })
            .catch((error) => {
                console.error('Error fetching discipline', error);
            });
    }, [])

    useEffect(() => {
        axios.get(`${apiUrl}/disciplineCourse/`)
            .then((response) => {
                setSelectedDisciplineCourse(response.data.data);
            })
            .catch((error) => {
                console.error('Error fetching discipline', error);
            });
    }, [])

    useEffect(() => {
        axios.get(`${apiUrl}/studentarrear/`)
            .then((response) => {
                setStudentarrearList(response.data.data);
            })
            .catch((error) => {
                console.error('Error fetching student arrear', error);
            });
    }, [])


    useEffect(() => {
        dispatch(fetchStudentMarkAction())
    }, [])

    const getDisciplineCourseId = (selectedCourseId: string) => {
        // console.log(selectedCourseId);
        return disciplineCourseList.find(f => f.id === parseInt(selectedCourseId))?.courseId;
    };


    const handleArrear = async (dataItem: any, semester: number) => {

        const studentArrearData = {
            disciplineId: parseInt(dataItem.disciplineId),
            degreeId: parseInt(dataItem.degreeId),
            registerNo: dataItem.registerNumber,
            semesterNo: semester,
            disciplineCourseId: parseInt(dataItem.disciplineCourseId),
            status: 'Pending',
        };

        const arrearResponse = await axios.post(`${apiUrl}/studentarrear/`, studentArrearData);
        if (arrearResponse.data.error) {
            console.log(arrearResponse.data.message, { position: 'top-right' });
        } else {
            console.log(arrearResponse.data.message, { position: 'top-right' });
        }

    };

    const handleDeleteArrear = async (dataItem: any, semester: number) => {

        let deleteArrearIfTheStudentPass = studentarrearList.find(sa =>
            sa.disciplineId === parseInt(dataItem.disciplineId) &&
            sa.degreeId === parseInt(dataItem.degreeId) &&
            sa.disciplineCourseId === parseInt(dataItem.disciplineCourseId) &&
            sa.registerNo === dataItem.registerNumber &&
            sa.semesterNo === semester
        )?.id;

        console.log(deleteArrearIfTheStudentPass)
        const response = await axios.delete(`${apiUrl}/studentarrear/?id=${deleteArrearIfTheStudentPass}`);
        if (response.data.error) {
            console.log(response.data.message, { position: 'top-right' });
        } else {
            console.log(response.data.message, { position: 'top-right' });
        }
    };

    const handleUpdateArrear = async (dataItem: any, semester: number) => {

        let UpdateArrearAfterAttendArrear = studentarrearList
            .filter(sa =>
                sa.disciplineId === parseInt(dataItem.disciplineId) &&
                sa.degreeId === parseInt(dataItem.degreeId) &&
                sa.disciplineCourseId === parseInt(dataItem.disciplineCourseId) &&
                sa.registerNo === dataItem.registerNumber &&
                sa.semesterNo !== semester
            )
            .map(sa => ({ id: sa.id, attemptCount: sa.attemptCount, status: sa.status }));


        if (UpdateArrearAfterAttendArrear.length > 0) {
            for (const arrear of UpdateArrearAfterAttendArrear) {
                const studentArrearData = {
                    attemptCount: arrear.status === 'Completed' ? arrear.attemptCount : arrear.attemptCount + 1,
                    clearedSemester: semester,
                    status: 'Completed',
                };

                const response = await axios.put(`${apiUrl}/studentarrear/?id=${arrear.id}`, studentArrearData);
                if (response.data.error) {
                    console.log(response.data.message, { position: 'top-right' });
                } else {
                    console.log(response.data.message, { position: 'top-right' });
                }
            }
        } else {
            console.log('No matching student arrears found.');
        }
    };

    const handleReArrear = async (dataItem: any, alreadyExistStudentMark: any, semester: number) => {
        console.log(dataItem);

        const studentMarkData = studentMarkList.find(sm => sm.id === alreadyExistStudentMark.id);

        if (studentMarkData && studentMarkData.grade === 0 && studentMarkData.gradeText === '') {
            let UpdateArrearAfterAttendArrear = studentarrearList
                ?.filter(sa =>
                    sa.disciplineId === parseInt(dataItem.disciplineId) &&
                    sa.degreeId === parseInt(dataItem.degreeId) &&
                    sa.disciplineCourseId === parseInt(dataItem.disciplineCourseId) &&
                    sa.registerNo === dataItem.registerNumber &&
                    sa.semesterNo !== semester
                )
                ?.map(sa => ({ id: sa.id, attemptCount: sa.attemptCount, status: sa.status }));

            if (UpdateArrearAfterAttendArrear.length > 0) {
                for (const arrear of UpdateArrearAfterAttendArrear) {
                    const studentArrearData = {
                        attemptCount: arrear.attemptCount + 1,
                        clearedSemester: 0,
                        status: 'Pending',
                    };

                    const response = await axios.put(`${apiUrl}/studentarrear/?id=${arrear.id}`, studentArrearData);
                    if (response.data.error) {
                        console.log(response.data.message, { position: 'top-right' });
                    } else {
                        console.log(response.data.message, { position: 'top-right' });
                    }
                }
            } else {
                console.log('No matching student arrears found.');
            }
        } else {
            console.log('For this student arrear record already exists');
        }
    };

    const handleReArrearEdit = async (dataItem: any, semester: number) => {

        let UpdateArrearAfterAttendArrear = studentarrearList
            .filter(sa =>
                sa.disciplineId === parseInt(dataItem.disciplineId) &&
                sa.degreeId === parseInt(dataItem.degreeId) &&
                sa.disciplineCourseId === parseInt(dataItem.disciplineCourseId) &&
                sa.registerNo === dataItem.registerNumber &&
                sa.semesterNo !== semester
            )
            .map(sa => ({ id: sa.id, attemptCount: sa.attemptCount }));

        if (UpdateArrearAfterAttendArrear.length > 0) {
            for (const arrear of UpdateArrearAfterAttendArrear) {
                const studentArrearData = {
                    attemptCount: arrear.attemptCount,
                    clearedSemester: 0,
                    status: 'Pending',
                };

                const response = await axios.put(`${apiUrl}/studentarrear/?id=${arrear.id}`, studentArrearData);
                if (response.data.error) {
                    console.log(response.data.message, { position: 'top-right' });
                } else {
                    console.log(response.data.message, { position: 'top-right' });
                }
            }
        } else {
            console.log('No matching student arrears found.');
        }
    };

    useEffect(() => {
        // const filteredStudents = studentMarkList.filter((student) => {
        //     const isDummyInRange =
        //         (dummyNumberFromFilter === '' || parseInt(student.dummyNumber!) >= parseInt(dummyNumberFromFilter)) &&
        //         (dummyNumberToFilter === '' || parseInt(student.dummyNumber!) <= parseInt(dummyNumberToFilter));

        //     return (
        //         (examFilter === '' || student.examId?.toString() === examFilter) &&
        //         (degreeFilter === '' || student.degreeId?.toString() === degreeFilter) &&
        //         (disciplineFilter === '' || student.disciplineId?.toString() === disciplineFilter) &&
        //         (semesterFilter === '' || student.semester?.toString() === semesterFilter) &&
        //         isDummyInRange
        //     );
        // });
        let isDummyFromToExists = dummyNumberFromFilter !== '' && dummyNumberToFilter !== ''

        const filteredStudents = studentMarkList.filter(s => {
            return s.examId?.toString() === (examFilter !== '' ? examFilter : s.examId?.toString()) &&
                s.degreeId?.toString() === (degreeFilter !== '' ? degreeFilter : s.degreeId?.toString()) &&
                s.disciplineId?.toString() === (disciplineFilter !== '' ? disciplineFilter : s.disciplineId?.toString()) &&
                // s.disciplineCourseId?.toString() === (courseFilter !== '' ? courseFilter : s.disciplineCourseId?.toString()) &&
                (courseFilter === '' || getDisciplineCourseId(s.disciplineCourseId?.toString()) === parseInt(courseFilter)) &&
                s.semester?.toString() === (semesterFilter !== '' ? semesterFilter : s.semester?.toString()) &&
                s.batch?.toString() === (batchFilter !== '' ? batchFilter : s.batch?.toString()) &&
                parseInt(s.dummyNumber!) >= (isDummyFromToExists ? parseInt(dummyNumberFromFilter) : parseInt(s.dummyNumber!)) &&
                parseInt(s.dummyNumber!) <= (isDummyFromToExists ? parseInt(dummyNumberToFilter) : parseInt(s.dummyNumber!))
        })
        // console.log('hi');
        // console.log(filteredStudents);

        const checkFilteredStudents = filteredStudents

        setFilteredStudentList(checkFilteredStudents);

    }, [examFilter, degreeFilter, disciplineFilter, courseFilter, semesterFilter, batchFilter, dummyNumberFromFilter, dummyNumberToFilter, studentMarkList]);


    const resetStates = () => {
        setExam('')
        setExamError('')
        setDegree('')
        setDegreeError('')
        setDiscipline('')
        setDisciplineError('')
        setSemester('')
        setSemesterError('')
        setRegisterNo('')
        setChiefExaminer('')
        setDate('')
        setSession('')
        setDummyNumberFrom('')
        setDummyNumberTo('')
        setRegisterNoError('')
        setDummyNo('')
        setDummyNoError('')
        setDisciplineCourse('')
        setDisciplineCourseError('')
        setQuestionPaper('')
        setQuestionPaperError('')
        setSecuredMark('')
        setSecuredMarkError('')
        setConvertedMark('')
        setConvertedMarkError('')
        setAdjustedMark('')
        setAdjustedMarkError('')
        setIntExaminer('')
        setIntExaminerError('')
        setExtExaminer('')
        setExtExaminerError('')
        setEditFlag(false)
        setSelectedDummyNoList([]);
        setSecuredMarks([]);
        setIntExaminers([]);
        setExtExaminers([]);
        setDataList([]);
        setSecuredMarkError('');
        setAvgMark('');
    }


    const saveHandler = () => {
        let error = false

        const addData: StudentMarkType = {
            examId: parseInt(exam),
            degreeId: parseInt(degree),
            disciplineId: parseInt(discipline),
            semester: parseInt(semester),
            registerNumber: registeNo,
            dummyNumber: dummyNo,
            disciplineCourseId: parseInt(disciplineCourse),
            questionPaperCode: questionPaper,
            extMark: parseInt(securedMark),
            extConvertedMark: parseInt(convertedMark),
            adjustedMark: parseInt(adjuestedMark),
            intExaminerId: parseInt(intExaminer),
            extExaminerId: parseInt(extExaminer)
        }


        if (exam === '') {
            setExamError('Exam required');
            error = true
        }
        if (degree === '') {
            setDegreeError('Degree required');
            error = true
        }
        if (discipline === '') {
            setDisciplineError('Discipline required');
            error = true
        }
        if (semester === '') {
            setSemesterError('Semester required');
            error = true
        }
        if (registeNo === '') {
            setRegisterNoError('Register Number required');
            error = true
        }
        if (dummyNo === '') {
            setDummyNoError('Dummy Number required');
            error = true
        }
        if (disciplineCourse === '') {
            setDisciplineCourseError('Discipline Course required');
            error = true
        }
        if (questionPaper === '') {
            setQuestionPaperError('Question Paper Code required');
            error = true
        }
        if (securedMark === '') {
            setSecuredMarkError('Secured Mark required');
            error = true
        }
        if (convertedMark === '') {
            setConvertedMarkError('Converted Mark required');
            error = true
        }
        if (adjuestedMark === '') {
            setAdjustedMarkError('Adjusted Mark required');
            error = true
        }
        if (intExaminer === '') {
            setIntExaminerError('Int Examiner required');
            error = true
        }
        if (extExaminer === '') {
            setExtExaminerError('Ext Examiner required');
            error = true
        }


        if (!error) {
            dispatch(addStudentMarkAction(addData)).then(text => {
                toast.success(text)
                resetStates()
            }).catch(text => {
                toast.error(text)
            })

        }
    }

    const editClickHandler = (sm: StudentMarkType) => {

        // setEditFlag(true);
        setEditModalShow(true)
        setEditExam(sm.examId?.toString());
        setEditDegree(sm.degreeId?.toString());
        setEditDiscipline(sm.disciplineId?.toString());
        setEditSemester(sm.semester?.toString());
        setEditRegisterNo(sm.registerNumber);
        setEditDummyNo(sm.dummyNumber ? sm.dummyNumber : '');
        setEditDisciplineCourse(sm.disciplineCourseId?.toString());
        setEditQuestionPaperCode(sm.questionPaperCode);
        setEditSecuredMark(sm.extMark ? sm.extMark?.toString() : '');
        // setEditConvertedMark(sm.convertedMark ? sm.convertedMark?.toString() : '');
        setEditIntExaminer(sm.intExaminerId ? sm.intExaminerId?.toString() : '');
        setEditExtExaminer(sm.extExaminerId ? sm.extExaminerId?.toString() : '');
        setEditChiefExaminer(sm.chiefExaminerId ? sm.chiefExaminerId?.toString() : '');
        setId(sm.id ? sm.id : 0);

        api.get(`studentMark/thoeryUpdate/?regNo=${sm?.registerNumber}&disciplineCourseId=${sm?.disciplineCourseId?.toString()}`, config)
            .then((response) => {
                if (response.status === 200 && response.data.data) {
                    const result = response.data.data
                    setEditIntMark(result.internalMark?.toString())
                }
            })
            .catch((error) => {
                console.error('Error fetching intMark', error);
                // toast.error(error.response.data.message)
            });
    }

    const singleEditHandler = () => {
        let error = false

        if (editSecuredMark === '') {
            setEditSecuredMarkError('Secured Mark required');
            error = true
        } else if (parseInt(editSecuredMark) < -1 || parseInt(editSecuredMark) > 100) {
            setEditSecuredMarkError('Invaild Mark')
            error = true
        }

        if (editIntExaminer === '') {
            setEditIntExaminerError('Int Examiner required');
            error = true
        } else if (parseInt(editIntExaminer) <= 0) {
            setEditIntExaminerError('Invalid Int Examiner');
            error = true
        }

        if (editExtExaminer === '') {
            setEditExtExaminerError('Ext Examiner required');
            error = true
        } else if (parseInt(editExtExaminer) <= 0) {
            setEditExtExaminerError('Invalid Ext Examiner');
            error = true
        }

        if (editChiefExaminer === '') {
            setEditChiefExaminerError('Chief Examiner required');
            error = true
        } else if (parseInt(editChiefExaminer) <= 0) {
            setEditChiefExaminerError('Invalid Chief Examiner');
            error = true
        }
        let discourseObj = disciplineCourseList?.find(dc => dc.id?.toString() === editDisciplineCourse)

        let intConvRate = discourseObj?.intConversion ? discourseObj?.intConversion : 0;
        let extConvRate = discourseObj?.extConversion ? discourseObj?.extConversion : 0;

        let intMark = parseFloat(editIntMark);
        let intConvertedMark = (intMark / 100) * intConvRate;

        // let extConvertedMark = (parseFloat(editSecuredMark) / 100) * extConvRate;
        let extConvertedMark = parseInt(editSecuredMark) === -1 ? 0 : (parseFloat(editSecuredMark) / 100) * extConvRate;

        let totConvertedMark = Math.round(intConvertedMark + extConvertedMark);

        let grade = calculateGrade(totConvertedMark, extConvertedMark);
        let gradeText = calculateGradeText(totConvertedMark, extConvertedMark);

        const editData: StudentMarkType = {
            examId: parseInt(editExam),
            degreeId: parseInt(editDegree),
            disciplineId: parseInt(editDiscipline),
            semester: parseInt(editSemester),
            registerNumber: editRegisterNo,
            dummyNumber: editDummyNo,
            disciplineCourseId: parseInt(editDisciplineCourse),
            questionPaperCode: editQuestionPaperCode,
            intMark: intMark,
            intConvertedMark: intConvertedMark,
            extMark: parseInt(editSecuredMark),
            extConvertedMark: extConvertedMark,
            totConvertedMark: totConvertedMark,
            grade: grade,
            gradeText: gradeText,
            adjustedMark: 0,
            intExaminerId: parseInt(editIntExaminer),
            extExaminerId: parseInt(editExtExaminer),
            chiefExaminerId: parseInt(editChiefExaminer),
        }

        if (!error && editData && id) {

            api.put(`studentMark/thoeryUpdate/?id=${id}`, editData, config)
                .then((response) => {
                    toast.success(response.data.message)
                    handleFetchDataForMarksheetAndUpdateStudentGrades(editExam, editDegree, editDiscipline, '', editSemester)
                    setEditModalShow(false)
                    resetStates()
                    dispatch(fetchStudentMarkAction())
                })
                .catch((error) => {
                    // console.error('Error fetching discipline', error);
                    toast.error(error.response.data.message)
                });



            let getSemesterForCheck = studentarrearList.find(sa =>
                sa.disciplineId === parseInt(editDiscipline) &&
                sa.degreeId === parseInt(editDegree) &&
                sa.disciplineCourseId === parseInt(editDisciplineCourse) &&
                sa.registerNo === editRegisterNo &&
                sa.semesterNo === parseInt(editSemester)
            )?.semesterNo || 0;

            let getSemesterFromDisCourse = disciplineCourseList.find(dc => dc.id === parseInt(editDisciplineCourse))?.semesterNo

            if (editData.grade === 0 && editData.gradeText === 'U' && getSemesterFromDisCourse === parseInt(editSemester)) {
                handleArrear(editData, parseInt(editSemester));
            } else if (editData.grade && editData.grade > 0 && editData.gradeText !== 'U' && getSemesterForCheck === parseInt(editSemester)) {
                handleDeleteArrear(editData, parseInt(editSemester));
            } else if (editData.grade && editData.grade > 0 && editData.gradeText !== 'U' && getSemesterForCheck === 0) {
                handleUpdateArrear(editData, parseInt(editSemester));
            } else if (editData.grade === 0 && editData.gradeText === 'U' && getSemesterForCheck === 0) {
                handleReArrearEdit(editData, parseInt(editSemester));
            }
        }

    }

    const handleDownloadPDF = () => {
        const doc = new jsPDF('p', 'mm', 'a4');
        let examName = examList.find(f => f.id?.toString() === exam)?.title
        // let degreeName = degreeList.find(d => d.id?.toString() === degree)?.name
        // let disciplineName = disciplineList.find(d => d.id?.toString() === discipline)?.name
        let courseName = courseList.find(d => d.id?.toString() === disciplineCourse)?.name
        let courseCode = courseList.find(d => d.id?.toString() === disciplineCourse)?.shortName

        doc.addImage(imgPath, 'PNG', 20, 10, 170, 40);
        doc.setFontSize(13);
        doc.setFont('Arial', 'normal', 'bold')
        doc.text(`${examName}`, 75, 50, { align: 'justify' });

        doc.setFontSize(11);
        // doc.text(`College : 8213 - PARISUTHAM INSTITUTE OF TECHNOLOGY AND SCIENCE`, 15, 40)
        // doc.text(`Degree & Discipline : ${degreeName} - ${disciplineName}`, 15, 70)
        doc.text(`Course Code / Title : ${courseCode} - ${courseName}`, 15, 58)
        doc.text(`Semester : ${semester}`, 15, 68, { align: 'left' })
        doc.text(`QP Code : ${dataList[0]?.questionPaperCode}`, 85, 68, { align: 'center' })
        doc.text(`Date & Session: ${LocalFormatDate(date)}  ${session}`, 135, 68)
        // doc.text(`Session : ${session}`, 155, 68)
        doc.setFontSize(13);
        doc.text('MARK SHEET', 90, 78, { align: 'justify' })

        autoTable(doc, {
            startY: 87,
            headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], lineWidth: 0.3, lineColor: [0, 0, 0], fontSize: 9 },
            bodyStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], lineWidth: 0.3, lineColor: [0, 0, 0], halign: 'center', fontSize: 9 },
            head: [
                [
                    { content: "S.No.", styles: { halign: 'center' } },
                    { content: "Dummy Number", styles: { halign: 'center' } },
                    { content: "Marks", styles: { halign: 'center' } },
                    { content: "Mark in Words", styles: { halign: 'center' } }
                ]

            ],
            body: dataList?.map((pm, i) => {
                return [(i + 1)?.toString(), pm.dummyNumber, pm.extMark,
                { content: `${inWords(parseFloat(pm.extMark))}`, styles: { halign: 'left' } },
                ]
            }),
            foot: [[{ content: "AVERAGE", styles: { halign: 'center' }, colSpan: 2 },
            { content: `${avgMark}`, styles: { halign: 'center' } },
            { content: `${inWords(parseFloat(avgMark))}`, styles: { halign: 'left' } },
            ]],
            footStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], lineWidth: 0.3, lineColor: [0, 0, 0], fontSize: 9 },
            theme: 'grid',
            tableLineColor: "black",
            margin: { left: 15 }
        })

        const intExnr = facultyList.find(faculty => faculty?.id?.toString() === intExaminer)?.name;
        const extExnr = facultyList.find(faculty => faculty?.id?.toString() === extExaminer)?.name;
        const chiefExnr = facultyList.find(faculty => faculty?.id?.toString() === chiefExaminer)?.name;

        doc.setFontSize(10)
        doc.setFont('Arial', 'normal', 'bold');
        doc.text(`ASSISTANT EXAMINER`, 12, (doc as any).lastAutoTable.finalY + 20)
        doc.text(`EXAMINER`, 95, (doc as any).lastAutoTable.finalY + 20)
        doc.text(`CHIEF EXAMINER`, 165, (doc as any).lastAutoTable.finalY + 20)
        doc.text(`(${intExnr})`, 15, (doc as any).lastAutoTable.finalY + 35)
        doc.text(`(${extExnr})`, 94, (doc as any).lastAutoTable.finalY + 35)
        doc.text(`(${chiefExnr})`, 164, (doc as any).lastAutoTable.finalY + 35)

        doc.save('Student_theory_mark_report.pdf');

    }

    function calculateDummyNumberCount(start: number, end: number): number {
        const count = Math.abs(end - start) + 1;
        return count;
    }

    const handleFetchDataForMarksheetAndUpdateStudentGrades = (exam: string, degree: string, discipline: string, batch: string, semester: string) => {
        if (exam !== '' && degree !== '' && discipline !== '' && batch !== '' && semester !== '') {
            // let years = academicYear.split('-')
            axios.get<{ data: MarkSheetReportType[] }>(`${apiUrl}/student/marksheet/?degreeId=${degree}&disciplineId=${discipline}&examId=${exam}&batch=${batch}&sem=${semester}`).then(async (response) => {
                if (response.status === 200 && response.data.data) {
                    const result = response.data.data;
                    const AddGradeData: StudentGrade[] = [];
                    // setMarksheetData(response.data.data);
                    let totalCreditsRegistered = 0;
                    let totalCreditsEarned = 0;
                    let totalGradePointsEarned = 0;
                    let GPA = 0;
                    let cumulativeCredits = 0;
                    let sem = 0;

                    result.length > 0 && result.forEach(h => {
                        h?.courseDetails?.forEach(cd => {
                            sem = cd.sem;
                            totalCreditsRegistered += parseInt(cd.credit)
                            totalGradePointsEarned += (cd.unvGrade)
                            if (cd.unvGrade > 0) {
                                totalCreditsEarned += parseInt(cd.credit)
                                cumulativeCredits += parseInt(cd.credit)
                                GPA += (cd.unvGrade) * parseInt(cd.credit)
                            }
                        })
                        GPA = GPA / totalCreditsEarned;
                        let obj = {
                            registeNo: h.registerNo,
                            batch: batch.toString(),
                            sem: sem,
                            cRegistered: totalCreditsRegistered.toString(),
                            cEarned: totalCreditsEarned.toString(),
                            gpEarned: totalGradePointsEarned.toString(),
                            gpAverage: GPA.toFixed(2),
                        }

                        AddGradeData.push(obj)
                    });
                    console.log(AddGradeData);

                    if (AddGradeData) {
                        await axios.post(`${apiUrl}/studentGrade/bulkInsert/`, AddGradeData).then((response) => {
                            if (response.status === 200) {
                                toast.success('Grades Updated');
                                // dispatch(fetchStudentMarkAction());
                                // resetStates();
                                // handleDownloadPDF(editData);
                            }
                        }).catch((error) => {
                            if (error!.response) {
                                toast.error(error.response.data.message);
                            }
                        });
                    }

                }
            })
                .catch((error) => {
                    console.error('Error fetching marksheet data', error);
                });
        } else {
            toast.error('Select All Filters', { position: 'top-right', autoClose: 3000 });
        }
    }

    const thoeryMarksBulkUpdateHandler = async () => {
        let error = false;
        if (dataList.length === 0) {
            error = true;
            toast.error('Data Required');
            return;
        }

        const editData = [];

        for (const sm of dataList) {
            try {
                const response = await axios.get(`${apiUrl}/disciplineCourse/?id=${sm.disciplineCourseId}`);

                if (response.status === 200 && response.data.data) {
                    setConvertion(response.data.data);
                    const ConversionResult = response.data.data;

                    const securedMark = sm.extMark;
                    const intConversion = sm.intConvertedMark;
                    const extConversion = ConversionResult ? ConversionResult.extConversion : 0;

                    // const convertedExternalMark = Math.round((parseFloat(securedMark) / 100) * extConversion);
                    const convertedExternalMark = parseInt(securedMark) === -1 ? 0 : Math.round((parseFloat(securedMark) / 100) * extConversion);
                    const totalConvertedMark = intConversion! + convertedExternalMark;

                    const grade = calculateGrade(totalConvertedMark, convertedExternalMark);
                    const gradeText = calculateGradeText(totalConvertedMark, convertedExternalMark);
                    if (securedMark === '') {
                        error = true;
                        toast.error(`Secured mark required for Dummy No ${sm.dummyNumber} `, { position: 'top-right' });
                        continue;
                    } else if (securedMark === null || parseFloat(securedMark) < -1 || isNaN(parseFloat(securedMark))) {
                        error = true;
                        toast.error(`Invalid Secured mark for Dummy No ${sm.dummyNumber} `, { position: 'top-right' });
                        continue;
                    }

                    let degreeIdWithoutdegree = disciplineCourseList
                        .filter(f => f.courseId?.toString() === disciplineCourse)
                        .map(course => course.degreeId);

                    let commonDegreeIds = degreeIdWithoutdegree.filter(degreeId => dataList.some(item => parseInt(item.degreeId) === degreeId));
                    let uniqueDegreeIds = Array.from(new Set(commonDegreeIds));
                    console.log(uniqueDegreeIds);

                    //
                    let disciplineIdWithoutDiscipline = disciplineCourseList
                        .filter(f => f.courseId?.toString() === disciplineCourse)
                        .map(course => course.disciplineId);

                    let commonDisciplineIds = disciplineIdWithoutDiscipline.filter(disciplineId => dataList.some(item => parseInt(item.disciplineId) === disciplineId));
                    console.log(commonDisciplineIds)


                    let disCourseId = disciplineCourseList.find(f => f.degreeId?.toString() === degree && f.disciplineId?.toString() === discipline && f.courseId?.toString() === disciplineCourse)?.id
                    let disCourseIdWithoutDiscipline = disciplineCourseList
                        .filter(f => f.courseId?.toString() === disciplineCourse)
                        .map(course => course.id);

                    let commonDisciplineCourseIds = disCourseIdWithoutDiscipline.filter(id => dataList.some(item => parseInt(item.disciplineCourseId) === id));
                    //

                    // let disciplineIdWithoutDiscipline = disciplineCourseList
                    //     .filter(f => f.degreeId?.toString() === degree && f.courseId?.toString() === disciplineCourse)
                    //     .map(course => course.disciplineId);

                    // let commonDisciplineIds = disciplineIdWithoutDiscipline.filter(disciplineId => dataList.some(item => {
                    //     // console.log(item.disciplineId);
                    //     // console.log(disciplineId);

                    //     return parseInt(item.disciplineId) === disciplineId
                    // }));

                    // let disCourseId = disciplineCourseList.find(f => f.degreeId?.toString() === degree && f.disciplineId?.toString() === discipline && f.courseId?.toString() === disciplineCourse)?.id

                    // let disCourseIdWithoutDiscipline = disciplineCourseList
                    //     .filter(f => f.degreeId?.toString() === degree && f.courseId?.toString() === disciplineCourse)
                    //     .map(course => course.id);

                    // let commonDisciplineCourseIds = disCourseIdWithoutDiscipline.filter(id => dataList.some(item => parseInt(item.disciplineCourseId) === id));


                    editData.push({
                        id: sm.id,
                        extMark: securedMark,
                        extConvertedMark: convertedExternalMark,
                        totConvertedMark: totalConvertedMark,
                        intExaminerId: intExaminer,
                        extExaminerId: extExaminer,
                        chiefExaminerId: chiefExaminer,
                        examId: exam,
                        degreeId: degree === "" ? uniqueDegreeIds : degree,
                        disciplineId: discipline === "" ? commonDisciplineIds : discipline,
                        disciplineCourseId: disCourseId === undefined ? commonDisciplineCourseIds : disCourseId,
                        date: date,
                        session: session,
                        startingDummyNo: parseInt(dummyNumberFrom),
                        endingDummyNo: parseInt(dummyNumberTo),
                        count: calculateDummyNumberCount(parseInt(dummyNumberFrom), parseInt(dummyNumberTo)),
                        avgMark: parseInt(avgMark),
                        examinerId: parseInt(extExaminer),
                        asstExaminerId: parseInt(intExaminer),
                        grade: grade,
                        gradeText: gradeText,
                    });
                }
            } catch (error) {
                console.log(error);
            }
        }

        if (!error && editData.length > 0) {
            try {
                const response = await axios.put(`${apiUrl}/studentMark/updateMark/`, editData);
                console.log(response.data.message);
                if (response.data.error) {
                    toast.error(response.data.message, { position: 'top-right' });
                } else {
                    toast.success(response.data.message, { position: 'top-right' });
                    handleFetchDataForMarksheetAndUpdateStudentGrades(exam, degree, discipline, batch, semester)
                    dispatch(fetchStudentMarkAction());
                    handleDownloadPDF();
                    resetStates();
                }

                for (let i = 0; i < editData.length && i < dataList.length; i++) {
                    const editItem = editData[i];
                    const dataItem = dataList[i];

                    let getSemesterForCheck = studentarrearList.find(sa =>
                        sa.disciplineId === parseInt(dataItem.disciplineId) &&
                        sa.degreeId === parseInt(dataItem.degreeId) &&
                        sa.disciplineCourseId === parseInt(dataItem.disciplineCourseId) &&
                        sa.registerNo === dataItem.registerNumber &&
                        sa.semesterNo === parseInt(semester)
                    )?.semesterNo || 0;

                    let getSemesterFromDisCourse = disciplineCourseList.find(dc => dc.id === parseInt(dataItem.disciplineCourseId))?.semesterNo

                    if (editItem.grade === 0 && editItem.gradeText === 'U' && getSemesterFromDisCourse === parseInt(semester)) {//Arrear in 1st sem-add
                        await handleArrear(dataItem, parseInt(semester));
                    } else if (editItem.grade > 0 && editItem.gradeText !== 'U' && getSemesterForCheck === parseInt(semester)) {//updating arrear to pass mark in 1st sem-delete arr record
                        console.log('Delete')
                        await handleDeleteArrear(dataItem, parseInt(semester));
                    } else if (editItem.grade > 0 && editItem.gradeText !== 'U' && getSemesterForCheck === 0) {//Updating arrear in current sem-UPDATE pass status
                        console.log('update')
                        await handleUpdateArrear(dataItem, parseInt(semester));
                    } else if (editItem.grade === 0 && editItem.gradeText === 'U' && getSemesterForCheck === 0) {//Updating arrear in current sem-UPDATE pending status(again arrear)
                        console.log('handleReArrear')
                        handleReArrear(dataItem, editItem, parseInt(editSemester));
                    }
                }
            } catch (error: any) {
                console.error(error.response.data.message);
                toast.error(error.response.data.message, { position: 'top-right' });
            }
        }

    };

    const openDeleteConfirmation = () => {
        setShowDeleteConfirmation(true);
    };

    const closeDeleteConfirmation = () => {
        setShowDeleteConfirmation(false);
    };


    const deleteHandler = (id: number) => {
        setId(id)
        openDeleteConfirmation()
    }

    const confirmDeleteHandler = () => {
        if (id !== undefined) {
            dispatch(deleteStudentMarkAction(id)).then(text => {
                toast.success(text)
                closeDeleteConfirmation();
            }).catch(text => {
                toast.error(text);
            })
        }
    }

    const getExamName = (examId: string) => {
        const exam = selectedExam.find((ex) => ex.id === parseInt(examId));
        if (exam) {
            return exam.title;
        }
        return 'N/A';
    }

    const getDegreeName = (degreeId: string) => {
        const degree = selectedDegree.find((deg) => deg.id === parseInt(degreeId));
        if (degree) {
            return degree.name;
        }
        return 'N/A';
    }

    const getDisciplineName = (disciplineId: string) => {
        const discipline = selectedDiscipline.find((dis) => dis.id === parseInt(disciplineId));
        if (discipline) {
            return discipline.name;
        }
        return 'N/A';
    }

    // const getDisciplineCourse = (disciplineCourseId: string) => {
    //     const disciplineCourse = selectedCourse.find((dc) => dc.id === parseInt(disciplineCourseId));
    //     if (disciplineCourse) {
    //         return disciplineCourse.name;
    //     }
    //     return 'N/A';
    // }
    const getDisciplineCourse = (disciplineCourseId: string) => {
        const CourseId = disciplineCourseList?.find((dc) => dc?.id?.toString() === disciplineCourseId?.toString())?.courseId

        const courseObj = courseList?.find((dc) => dc.id?.toString() === CourseId?.toString())

        if (courseObj) {
            return courseObj?.name ? courseObj?.name : '';
        }
        return 'N/A';
    }
    const getFacultyName = (disciplineCourseId: number) => {
        const disciplineCourse = facultyList.find((dc) => dc.id === disciplineCourseId);
        if (disciplineCourse) {
            return `${disciplineCourse.code} - ${disciplineCourse.name}`;
        }
        return 'N/A';
    }

    //   const getCourseName = (courseId: string) => {
    //     const disciplineCourse = sele.find((dc) => dc.id === parseInt(courseId));
    //     if (disciplineCourse) {
    //       const course = selectedCourse.find((c) => c.id == parseInt(disciplineCourse.courseId));
    //       if (course) {
    //         return course.name; 
    //       }
    //     }
    //     return 'N/A';
    //   };

    // const getDisciplineCourseId = (degreeId: string, disciplineId: string, disciplineCourseId: string) => {
    //     const selectedCourseIdInt = parseInt(disciplineCourseId);
    //     console.log(selectedCourseIdInt);
    //     const selectedDisciplineIdInt = parseInt(disciplineId);
    //     const selectedDegreeIdInt = parseInt(degreeId);
    //     const course = selectedCourse.find((c) => c.id === selectedCourseIdInt);
    //     console.log(course);
    //     const discipline = selectedDiscipline.find((c) => c.id === selectedDisciplineIdInt);
    //     const degree = selectedDegree.find((c) => c.id === selectedDegreeIdInt);
    //     if (course && discipline && degree) {
    //         const foundCourse = selectedDisciplineCourse.find((disciplineCourse) => disciplineCourse.courseId === course.id && disciplineCourse.disciplineId === discipline.id.toString() && disciplineCourse.degreeId === degree.id.toString());
    //         console.log(foundCourse);
    //         if (foundCourse) {
    //             return foundCourse.id;
    //         }
    //     }
    //     return 'N/A';
    // };

    // const disciplineCoursId = getDisciplineCourseId(degree, discipline, disciplineCourse);
    // console.log(disciplineCoursId);

    // const filterStudent = () => {
    //     if (
    //         degree !== '' &&
    //         // discipline !== '' &&
    //         exam !== '' &&
    //         disciplineCourse !== '' &&
    //         batch !== '' &&
    //         semester !== '' &&
    //         date !== '' &&
    //         session !== '' &&
    //         intExaminer != '' &&
    //         extExaminer !== '' &&
    //         chiefExaminer !== '' 
    //         // dummyNumberFrom !== '' &&
    //         // dummyNumberTo ! == ''
    //     ) {
    //         console.log(disciplineCourse)
    //         let disCourseId = disciplineCourseList.find(f => f.degreeId?.toString() === degree && f.disciplineId?.toString() === discipline && f.courseId?.toString() === disciplineCourse)?.id
    //         axios
    //             .get(`${apiUrl}/studentMark/?examId=${exam}&degreeId=${degree}&disciplineId=${discipline}&semester=${semester}&batch=${batch}&disciplineCourseId=${disCourseId}&from=${dummyNumberFrom}&to=${dummyNumberTo}`)
    //             .then((response) => {
    //                 setSelectedDummyNoList(response.data.data);
    //                 console.log(response.data.data);
    //             })
    //             .catch((error) => console.log(error));

    //         axios
    //             .get(`${apiUrl}/disciplineCourse/?degreeId=${degree}&disciplineId=${discipline}&courseId=${disciplineCourse}`)
    //             .then((response) => {
    //                 setConvertion(response.data.data);
    //                 console.log(response.data.data);
    //             })
    //             .catch((error) => console.log(error));
    //         setSearchPerformed(true);
    //     } else {
    //         toast.error('Please select all filters');

    //     }
    // };

    const filterStudent = () => {
        if (
            // degree !== '' &&
            // discipline !== '' &&
            exam !== '' &&
            disciplineCourse !== '' &&
            batch !== '' &&
            semester !== '' &&
            date !== '' &&
            session !== '' &&
            intExaminer !== '' &&
            extExaminer !== '' &&
            chiefExaminer !== ''
            // dummyNumberFrom !== '' &&
            // dummyNumberTo !== ''
        ) {
            const degId = degree === '' ? null : degree;
            const disId = discipline === '' ? null : discipline;
            console.log(disId)
            let disCourseIdList = disciplineCourseList
                .filter(f => f.courseId?.toString() === disciplineCourse)
                .map(course => course.id);

            // console.log(disCourseIdList);

            axios.get(`${apiUrl}/studentMark/?examId=${exam}&degreeId=${degId}&disciplineId=${disId}&semester=${semester}&batch=${batch}&disciplineCourseId=${disCourseIdList.join(',')}&from=${dummyNumberFrom}&to=${dummyNumberTo}`)
                .then((response) => {
                    setSelectedDummyNoList(response.data.data);
                    console.log(response.data.data);
                })
                .catch((error) => console.log(error));
            setSearchPerformed(true);
        } else {
            toast.error('Please select all filters');
        }
    };

    useEffect(() => {
        const data = selectDummyNoList?.map((sm, i) => {
            // const extMark = sm.extMark !== undefined ? sm.extMark :securedMarks[i]
            return {
                id: sm.id,
                dummyNumber: sm.dummyNumber,
                registerNumber: sm.registerNumber,
                questionPaperCode: sm.questionPaperCode,
                degreeId: sm.degreeId,
                disciplineId: sm.disciplineId,
                disciplineCourseId: sm.disciplineCourseId,
                extMark: (sm.extMark) ? sm.extMark : '0',
                intMark: sm.intMark,
                intConvertedMark: sm.intConvertedMark,
            }
        });

        setDataList(data);
    }, [selectDummyNoList])


    useEffect(() => {
        const totalMarks = dataList.reduce((sum, item) => sum + parseInt(item.extMark || '0', 10), 0);
        const numberOfEntries = dataList.length;
        const calculatedAverage = numberOfEntries > 0 ? totalMarks / numberOfEntries : 0;
        setAvgMark(Math.round(calculatedAverage).toString())
    }, [dataList])

    return (
        <>
            <PageContainer title="Student Mark - Theory">
                <div className="m-3 border border-danger rounded">
                    <h6 className="text-danger m-3">
                        <b>Note</b>: <span className="text-lowercase">Please ensure that internal marks for your particular course are entered in the student assessment before entering external marks.</span>
                    </h6>
                </div>

                <Card title={editFlag ? "Edit Student Mark - Theory" : "Add Student Mark - Theory"}>
                    <div className="row">
                        <div className="col-4">
                            <label htmlFor="" className="mt-3 mb-3">Exam <span className="text-danger">*</span> : </label>
                            <select name="" id="" className="form-control" value={exam} onChange={(e) => {
                                setExam(e.target.value)
                                setExamError('')
                            }}>
                                <option value="" selected>Select Exam</option>
                                {selectedExam?.map((ex) => (
                                    <option key={ex.id} value={ex.id}>
                                        {ex.title}
                                    </option>
                                ))}
                            </select>
                            {examError && <div className="text-danger">{examError}</div>}
                        </div>

                        <div className="col-4" >
                            <label htmlFor="" className="mt-3 mb-3">Degree : </label>
                            <select name="" id="" className="form-control" value={degree} onChange={(e) => {
                                setDegree(e.target.value)
                                setDegreeError('')
                            }}>
                                <option value="" selected>Select Degree</option>
                                {selectedDegree?.map((deg) => (
                                    <option key={deg.id} value={deg.id}>
                                        {deg.name}
                                    </option>
                                ))}
                            </select>
                            {degreeError && <div className="text-danger">{degreeError}</div>}
                        </div>

                        <div className="col-4">
                            <label className="mt-3 mb-3">Discipline : </label>
                            <select name="" id="" className="form-control" value={discipline} onChange={(e) => {
                                setDiscipline(e.target.value)
                                console.log(discipline);
                                setDisciplineError('')
                            }}>
                                <option value="" selected>Select Discipline</option>
                                {selectedDiscipline?.map((disc) => (
                                    <option key={disc.id} value={disc.id}>
                                        {disc.name}
                                    </option>
                                ))}
                            </select>
                            {disciplineError && <div className="text-danger">{disciplineError}</div>}
                        </div>


                        {/* <div className="col-4">
                            <label className="mt-3 mb-3">Register Number<span className="text-danger">*</span> : </label>
                            <input type="text" className="form-control" value={registeNo} onChange={(e) => {
                                setRegisterNo(e.target.value)
                                setRegisterNoError('')
                            }} placeholder="Enter Register Number..." />
                            {registeNoError && <div className="text-danger">{registeNoError}</div>}
                        </div> */}

                        {/* <div className="col-4">
                            <label className="mt-3 mb-3">Dummy Number<span className="text-danger">*</span> : </label>
                            <input type="text" className="form-control" value={dummyNo} onChange={(e) => {
                                setDummyNo(e.target.value)
                                setDummyNoError('')
                            }} placeholder="Enter Dummy Number..." />
                            {dummyNoError && <div className="text-danger">{dummyNoError}</div>}
                        </div> */}

                        <div className="col-4">
                            <label className="mt-3 mb-3">Course <span className="text-danger">*</span> : </label>
                            <select name="" id="" className="form-control" value={disciplineCourse} onChange={(e) => {
                                setDisciplineCourse(e.target.value)
                                setDisciplineCourseError('')
                            }}>
                                <option value="" selected>Select Course</option>
                                {selectedCourse?.map((course) => (
                                    <option key={course.id} value={course.id}>
                                        {course.name}
                                    </option>
                                ))}
                            </select>
                            {disciplineCourseError && <div className="text-danger">{disciplineCourseError}</div>}
                        </div>

                        {/* 
                        <div className="col-4">
                            <label className="mt-3 mb-3">Question Paper Code<span className="text-danger">*</span> : </label>
                            <input type="text" className="form-control" value={questionPaper} onChange={(e) => {
                                setQuestionPaper(e.target.value)
                                setQuestionPaperError('')
                            }} placeholder="Enter Question PaperCode..." />
                            {questionPaperError && <div className="text-danger">{questionPaperError}</div>}
                        </div>*/}

                        <div className="col-4">
                            <label className="mt-3 mb-3">Semester <span className="text-danger">*</span> : </label>
                            <select name="" id="" className="form-control"
                                value={semester}
                                onChange={(e) => {
                                    setSemester(e.target.value)
                                    setSemesterError('')
                                }}>
                                <option value="" selected>Select Semester</option>
                                <option value="1" selected>1</option>
                                <option value="2" selected>2</option>
                                <option value="3" selected>3</option>
                                <option value="4" selected>4</option>
                                <option value="5" selected>5</option>
                                <option value="6" selected>6</option>
                                <option value="7" selected>7</option>
                                <option value="8" selected>8</option>
                            </select>
                            {semesterError && <div className="text-danger">{semesterError}</div>}
                        </div>

                        <div className="col-4">
                            <label className="mt-3 mb-3">Batch <span className="text-danger">*</span> : </label>
                            <select
                                className="form-control"
                                style={{ width: "100%", marginRight: "20px" }}
                                name="selectedBatch"
                                value={batch}
                                onChange={(e) => setBatch(e.target.value)}
                            >
                                <option value="" selected>Select Batch</option>
                                {last10Years.map((year) => (
                                    <option key={year} value={year}>
                                        {year}
                                    </option>
                                ))}
                            </select>
                            {batchError && <div className="text-danger">{batchError}</div>}
                        </div>


                        <div className="col-4">
                            <label className="mt-3 mb-3">Assistant Examiner <span className="text-danger">*</span> : </label>
                            <select
                                name=""
                                id=""
                                className="form-control"
                                value={intExaminer}
                                onChange={(e) => {
                                    setIntExaminer(e.target.value);
                                    setIntExaminerError('');
                                }}
                            >
                                <option value="" selected>
                                    Select Assistant Examiner
                                </option>
                                {/* {examFacultyList?.filter(f => f.type === 'examiner')?.map((examFaculty) => {
                                    // if (examFaculty.type === 'examiner') {
                                    const faculty = facultyList.find(faculty => faculty.id === examFaculty.facultyId);
                                    return (
                                        <option key={examFaculty.id} value={examFaculty.facultyId}>
                                            {faculty?.name}
                                        </option>
                                    );
                                    // }
                                    // return null;
                                })} */}
                                {Array.from(new Set(examFacultyList?.filter(f => f.type === 'assist.examiner')?.map(examFaculty => examFaculty.facultyId)))
                                    .map(facultyId => {
                                        const faculty = facultyList.find(faculty => faculty.id === facultyId);
                                        return (
                                            <option key={facultyId} value={facultyId}>
                                                {faculty?.name}
                                            </option>
                                        );
                                    })}
                            </select>
                            {intExaminerError && <div className="text-danger">{intExaminerError}</div>}
                        </div>


                        <div className="col-4">
                            <label htmlFor="" className="mt-3 mb-3">Examiner <span className="text-danger">*</span> : </label>
                            <select
                                name=""
                                id=""
                                className="form-control"
                                value={extExaminer}
                                onChange={(e) => {
                                    setExtExaminer(e.target.value);
                                    setExtExaminerError('');
                                }}
                            >
                                <option value="" selected>
                                    Select Examiner
                                </option>
                                {/* {
        examFacultyList?.filter(f => f.type === 'assist.examiner')?.map((examFaculty) => {
            // if (examFaculty.type === '') {
            const faculty = facultyList.find(faculty => faculty.id === examFaculty.facultyId);
            return (
                <option key={examFaculty.id} value={examFaculty.facultyId}>
                    {faculty?.name}
                </option>
            );
            // }
            // return null;
        })
    } */}
                                {Array.from(new Set(examFacultyList?.filter(f => f.type === 'examiner')?.map(examFaculty => examFaculty.facultyId)))
                                    .map(facultyId => {
                                        const faculty = facultyList.find(faculty => faculty.id === facultyId);
                                        return (
                                            <option key={facultyId} value={facultyId}>
                                                {faculty?.name}
                                            </option>
                                        );
                                    })}
                            </select >
                            {extExaminerError && <div className="text-danger">{extExaminerError}</div>}
                        </div >


                        <div className="col-4">
                            <label htmlFor="" className="mt-3 mb-3">Chief Examiner <span className="text-danger">*</span> : </label>
                            <select
                                name=""
                                id=""
                                className="form-control"
                                value={chiefExaminer}
                                onChange={(e) => {
                                    setChiefExaminer(e.target.value);
                                    setChiefExaminerError('');
                                }}
                            >
                                <option value="" selected>
                                    Select Chief Examiner
                                </option>
                                {/* {
        examFacultyList?.filter(f => f.type === 'chief.examiner')?.map((examFaculty) => {
            // if (examFaculty.type === 'chief examiner') {
            const faculty = facultyList?.find(faculty => faculty.id === examFaculty.facultyId);
            return (
                <option key={examFaculty.id} value={examFaculty.facultyId}>
                    {faculty?.name}
                </option>
            );
            // }
            // return null;
        })
    } */}
                                {Array.from(new Set(examFacultyList?.filter(f => f.type === 'chief.examiner')?.map(examFaculty => examFaculty.facultyId)))
                                    .map(facultyId => {
                                        const faculty = facultyList.find(faculty => faculty.id === facultyId);
                                        return (
                                            <option key={facultyId} value={facultyId}>
                                                {faculty?.name}
                                            </option>
                                        );
                                    })}
                            </select >
                            {chiefExaminerError && <div className="text-danger">{chiefExaminerError}</div>}
                        </div >

                        <div className="col-4">
                            <label className="mt-3 mb-3">Date <span className="text-danger">*</span> : </label>
                            <input
                                style={{ width: "100%", marginRight: "20px", }}
                                className="form-control"
                                placeholder="Enter date..."
                                type="date"
                                name="date"
                                onChange={(e) => setDate(e.target.value)}
                                value={date}
                            />
                        </div>

                        <div className="col-4">
                            <label className="mt-3 mb-3">Session <span className="text-danger">*</span> : </label>
                            <select
                                className="form-control"
                                style={{ width: "100%", marginRight: "20px", }}
                                name="session"
                                value={session}
                                onChange={(e) => setSession(e.target.value)}
                            >
                                <option value="" selected>Select Session</option>
                                <option value="AN" selected>AN</option>
                                <option value="FN" selected>FN</option>
                            </select>
                        </div>


                        <div className="col-4">
                            <label className="mt-3 mb-3">Dummy Number Range <span className="text-danger">*</span> :</label>
                            <div className="d-flex">
                                <input
                                    type="number"
                                    style={{ width: "45%" }}
                                    className="form-control"
                                    value={dummyNumberFrom}
                                    onChange={(e) => {
                                        const enteredValue = e.target.value;
                                        setDummyNumberFrom(enteredValue);
                                        setDummyNumberFromError('');
                                    }}
                                    placeholder="From"
                                />
                                <span className="mx-2">to</span>
                                <input
                                    type="number"
                                    style={{ width: "45%" }}
                                    className="form-control"
                                    value={dummyNumberTo}
                                    onChange={(e) => {
                                        const enteredValue = e.target.value;
                                        setDummyNumberTo(enteredValue);
                                        setDummyNumberToError('');
                                    }}
                                    placeholder="To"
                                />
                            </div>
                            {dummyNumberFromError && <div className="text-danger">{dummyNumberFromError}</div>}
                            {dummyNumberToError && <div className="text-danger">{dummyNumberToError}</div>}
                        </div>

                        <div className="col-12">
                            <button
                                type="button"
                                className="btn btn-sm btn-primary"
                                onClick={filterStudent}
                                style={{ width: "10%", marginTop: "60px", float: "right" }}
                            >
                                Search
                            </button>
                        </div>

                        <div className="container-fluid" style={{ marginTop: "30px" }}>
                            <table className="table table-success table-striped">
                                <thead>
                                    <tr>
                                        <th>S.No.</th>
                                        <th>Dummy Number</th>
                                        <th>Question Paper Code</th>
                                        <th>External Mark</th>
                                        {/* <th>Internal Examiner</th>
                                        <th>External Examiner</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        dataList?.map((sm, i) => (
                                            <tr key={i}>
                                                <td>{i + 1}</td>
                                                <td>{sm.dummyNumber}</td>
                                                <td>{sm.questionPaperCode}</td>
                                                <td>
                                                    <input
                                                        type="number"
                                                        style={{ width: "30%" }}
                                                        className="form-control"
                                                        value={sm.extMark}
                                                        onChange={(e) => {
                                                            const enteredValue = e.target.value;
                                                            // console.log(enteredValue);
                                                            const isValid = enteredValue === '' || (parseInt(enteredValue) >= -1 && parseInt(enteredValue) <= 100);

                                                            if (isValid) {
                                                                setDataList((prev) => {
                                                                    let updated = [...prev];
                                                                    updated[i].extMark = enteredValue;
                                                                    return updated;
                                                                });
                                                                setSecuredMarkError('');
                                                            } else {
                                                                setSecuredMarkError(`Mark must be within -1 to 100`);
                                                            }
                                                        }}
                                                        placeholder="Enter Secured Mark..."
                                                    />
                                                    {securedMarkError && <div className="text-danger">{securedMarkError}</div>}
                                                </td>

                                                {/* <td>
                                                    <select
                                                        name=""
                                                        id=""
                                                        className="form-control"
                                                        value={intExaminers[i]}
                                                        onChange={(e) => {
                                                            const newIntExaminers = [...intExaminers];
                                                            newIntExaminers[i] = e.target.value;
                                                            setIntExaminers(newIntExaminers);
                                                            setIntExaminerError('');
                                                        }}
                                                    >
                                                        <option value="" selected>
                                                            Select Internal Examiner
                                                        </option>
                                                        {facultyList.map((course) => (
                                                            <option key={course.id} value={course.id}>
                                                                {course.code} - {course.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </td>
                                                 */}
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>

                        {dataList.length > 0 && <div className="col-4">
                            <label className="mt-3 mb-3" style={{ width: "100%" }}>
                                Average Mark <span className="text-danger">*</span> :
                            </label>
                            <input
                                className="form-control"
                                placeholder="Enter adjusting mark..."
                                type="number"
                                name="averageMark"
                                value={avgMark}
                                readOnly
                                style={{ width: "30%" }}
                            />
                        </div>
                        }

                        {dataList.length > 0 && <div className="col-12 mt-5 my-3 py-2 hstack">
                            <button className="btn btn-sm btn-primary" style={{ float: "right" }} onClick={thoeryMarksBulkUpdateHandler}>Update</button>
                            <button className="btn btn-sm btn-secondary" style={{ marginRight: '10px', float: "right" }} onClick={resetStates}>Clear</button>
                        </div>
                        }
                    </div >
                </Card >

                <CollapseCard title="Theory Marks">
                    {/* <div className='container'> */}
                    <div className="row m-2 mb-3">
                        <div className="col-4">
                            <label htmlFor="" className="mt-2 mb-2">Exam : </label>
                            <select name="" id="" className="form-control" value={examFilter} onChange={(e) => {
                                setExamFilter(e.target.value)
                            }}>
                                <option value="" selected>Select Exam</option>
                                {selectedExam?.map((ex) => (
                                    <option key={ex.id} value={ex.id}>
                                        {ex.title}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className="col-4" >
                            <label htmlFor="" className="mt-2 mb-2">Degree : </label>
                            <select name="" id="" className="form-control" value={degreeFilter} onChange={(e) => {
                                setDegreeFilter(e.target.value)
                            }}>
                                <option value="" selected>Select Degree</option>
                                {selectedDegree?.map((deg) => (
                                    <option key={deg.id} value={deg.id}>
                                        {deg.name}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className="col-4">
                            <label className="mt-2 mb-2">Discipline : </label>
                            <select name="" id="" className="form-control" value={disciplineFilter} onChange={(e) => {
                                setDisciplineFilter(e.target.value)
                            }}>
                                <option value="" selected>Select Discipline</option>
                                {selectedDiscipline?.map((disc) => (
                                    <option key={disc.id} value={disc.id}>
                                        {disc.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="col-4">
                            <label className="mt-2 mb-2">Course  </label>
                            <select name="" id="" className="form-control" value={courseFilter} onChange={(e) => {
                                setCourseFilter(e.target.value)
                            }}>
                                <option value="" selected>Select Course</option>
                                {selectedCourse?.map((course) => (
                                    <option key={course.id} value={course.id}>
                                        {course.name}
                                    </option>
                                ))}
                            </select>
                        </div>


                        <div className="col-4">
                            <label className="mt-2 mb-2">Semester : </label>
                            <select name="" id="" className="form-control"
                                value={semesterFilter}
                                onChange={(e) => {
                                    setSemesterFilter(e.target.value)
                                }}>
                                <option value="" selected>Select Semester</option>
                                <option value="1" selected>1</option>
                                <option value="2" selected>2</option>
                                <option value="3" selected>3</option>
                                <option value="4" selected>4</option>
                                <option value="5" selected>5</option>
                                <option value="6" selected>6</option>
                                <option value="7" selected>7</option>
                                <option value="8" selected>8</option>
                            </select>
                        </div>
                        <div className="col-4">
                            <label className="mt-2 mb-2">Batch  : </label>
                            <select
                                className="form-control"
                                // style={{ width: "100%", marginRight: "20px" }}
                                name="batchFilter"
                                value={batchFilter}
                                onChange={(e) => setBatchFilter(e.target.value)}
                            >
                                <option value="" selected>Select Batch</option>
                                {last10Years?.map((year) => (
                                    <option key={year} value={year}>
                                        {year}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="col-4">
                            <label className="mt-2 mb-2">Dummy Number Range :</label>
                            <div className="d-flex">
                                <input
                                    type="number"
                                    style={{ width: "45%" }}
                                    className="form-control"
                                    value={dummyNumberFromFilter}
                                    onChange={(e) => {
                                        const enteredValue = e.target.value;
                                        setDummyNumberFromFilter(enteredValue);
                                    }}
                                    placeholder="From"
                                />
                                <span className="mx-2">-</span>
                                <input
                                    type="number"
                                    style={{ width: "45%" }}
                                    className="form-control"
                                    value={dummyNumberToFilter}
                                    onChange={(e) => {
                                        const enteredValue = e.target.value;
                                        setDummyNumberToFilter(enteredValue);
                                    }}
                                    placeholder="To"
                                />
                            </div>
                        </div>

                    </div>
                    {/* </div> */}
                    <div className="container-fluid">
                        <table className="table table-success table-striped">
                            <thead>
                                <tr>
                                    <th>S.No.</th>
                                    <th>Exam</th>
                                    <th>Degree</th>
                                    <th>Discipline</th>
                                    <th>Semester</th>
                                    <th>Batch</th>
                                    <th>Dummy Number</th>
                                    <th>Discipline Course</th>
                                    <th>Question Paper Code</th>
                                    <th>Secured Mark</th>
                                    {/* <th>Converted Mark</th> */}
                                    {/* <th>Adjusted Mark</th> */}
                                    <th>Internal Examiner</th>
                                    <th>External Examiner</th>
                                    <th>Chief Examiner</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {studentListFilter
                                    ?.filter((sm) => sm.dummyNumber !== "")
                                    ?.map((sm, i) => (
                                        <tr key={i}>
                                            <td>{i + 1}</td>
                                            <td>{getExamName(sm.examId?.toString())}</td>
                                            <td>{getDegreeName(sm.degreeId?.toString())}</td>
                                            <td>{getDisciplineName(sm.disciplineId?.toString())}</td>
                                            <td>{sm.semester}</td>
                                            <td>{sm.batch}</td>
                                            <td>{sm.dummyNumber}</td>
                                            <td>{getDisciplineCourse(sm.disciplineCourseId?.toString())}</td>
                                            <td>{sm.questionPaperCode}</td>
                                            <td>{sm.extMark}</td>
                                            {/* <td>{sm.convertedMark}</td> */}
                                            {/* <td>{sm.adjustedMark}</td> */}
                                            <td>{getFacultyName(sm.intExaminerId ? sm.intExaminerId : 0)}</td>
                                            <td>{getFacultyName(sm.extExaminerId ? sm.extExaminerId : 0)}</td>
                                            <td>{getFacultyName(sm.chiefExaminerId ? sm.chiefExaminerId : 0)}</td>
                                            <td>
                                                <button className="btn btn-primary" onClick={() => editClickHandler(sm)}>Edit</button>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </CollapseCard>
                <Modal show={editModalShow} onHide={() => setEditModalShow(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Student Mark - Theory</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-6">
                                <label className="mt-3 mb-3">Exam:</label>
                                <input className="form-control" type="text" value={getExamName(editExam)} readOnly />
                            </div>
                            <div className="col-6">
                                <label className="mt-3 mb-3">Degree:</label>
                                <input className="form-control" type="text" value={getDegreeName(editDegree)} readOnly />
                            </div>
                            <div className="col-6">
                                <label className="mt-3 mb-3">Discipline:</label>
                                <input className="form-control" type="text" value={getDisciplineName(editDiscipline)} readOnly />
                            </div>
                            <div className="col-6">
                                <label className="mt-3 mb-3">Discipline Course:</label>
                                <input className="form-control" type="text" value={getDisciplineCourse(editDisciplineCourse)} readOnly />
                            </div>
                            <div className="col-6">
                                <label className="mt-3 mb-3">Dummy No:</label>
                                <input className="form-control" type="text" value={editDummyNo} readOnly />
                            </div>
                            <div className="col-6">
                                <label className="mt-3 mb-3">Secured Mark:</label>
                                <input
                                    className="form-control"
                                    placeholder="Enter mark..."
                                    type="number"
                                    onChange={(e) => {
                                        setEditSecuredMark(e.target.value)
                                        setEditSecuredMarkError('')
                                    }}
                                    value={editSecuredMark}
                                />
                                <span className="text-danger">{editSecuredMarkError}</span>
                            </div>
                            <div className="col-4">
                                <label className="mt-3 mb-3">Assistant Examiner <span className="text-danger">*</span> : </label>
                                <select
                                    name=""
                                    id=""
                                    className="form-control"
                                    value={editIntExaminer}
                                    onChange={(e) => {
                                        setEditIntExaminer(e.target.value);
                                        setEditIntExaminerError('');
                                    }}
                                >
                                    <option value="" selected>Select Assistant Examiner</option>
                                    {/* {examFacultyList?.filter(f => f.type === 'examiner')?.map((examFaculty) => {
                                    // if (examFaculty.type === 'examiner') {
                                    const faculty = facultyList.find(faculty => faculty.id === examFaculty.facultyId);
                                    return (
                                        <option key={examFaculty.id} value={examFaculty.facultyId}>
                                            {faculty?.name}
                                        </option>
                                    );
                                    // }
                                    // return null;
                                })} */}
                                    {Array.from(new Set(examFacultyList?.filter(f => f.type === 'assist.examiner')?.map(examFaculty => examFaculty.facultyId)))
                                        .map(facultyId => {
                                            const faculty = facultyList.find(faculty => faculty.id === facultyId);
                                            return (
                                                <option key={facultyId} value={facultyId}>
                                                    {faculty?.name}
                                                </option>
                                            );
                                        })}
                                </select>
                                {editIntExaminerError && <div className="text-danger">{editIntExaminerError}</div>}
                            </div>


                            <div className="col-4">
                                <label htmlFor="" className="mt-3 mb-3">Examiner <span className="text-danger">*</span> : </label>
                                <select
                                    name=""
                                    id=""
                                    className="form-control"
                                    value={editExtExaminer}
                                    onChange={(e) => {
                                        setEditExtExaminer(e.target.value);
                                        setEditExtExaminerError('');
                                    }}
                                >
                                    <option value="" selected>
                                        Select Examiner
                                    </option>
                                    {/* {
        examFacultyList?.filter(f => f.type === 'assist.examiner')?.map((examFaculty) => {
            // if (examFaculty.type === '') {
            const faculty = facultyList.find(faculty => faculty.id === examFaculty.facultyId);
            return (
                <option key={examFaculty.id} value={examFaculty.facultyId}>
                    {faculty?.name}
                </option>
            );
            // }
            // return null;
        })
    } */}
                                    {Array.from(new Set(examFacultyList?.filter(f => f.type === 'examiner')?.map(examFaculty => examFaculty.facultyId)))
                                        .map(facultyId => {
                                            const faculty = facultyList.find(faculty => faculty.id === facultyId);
                                            return (
                                                <option key={facultyId} value={facultyId}>
                                                    {faculty?.name}
                                                </option>
                                            );
                                        })}
                                </select >
                                {editExtExaminerError && <div className="text-danger">{editExtExaminerError}</div>}
                            </div >


                            <div className="col-4">
                                <label htmlFor="" className="mt-3 mb-3">Chief Examiner <span className="text-danger">*</span> : </label>
                                <select
                                    name=""
                                    id=""
                                    className="form-control"
                                    value={editChiefExaminer}
                                    onChange={(e) => {
                                        setEditChiefExaminer(e.target.value);
                                        setEditChiefExaminerError('');
                                    }}
                                >
                                    <option value="" selected>Select Chief Examiner</option>
                                    {/* {
        examFacultyList?.filter(f => f.type === 'chief.examiner')?.map((examFaculty) => {
            // if (examFaculty.type === 'chief examiner') {
            const faculty = facultyList?.find(faculty => faculty.id === examFaculty.facultyId);
            return (
                <option key={examFaculty.id} value={examFaculty.facultyId}>
                    {faculty?.name}
                </option>
            );
            // }
            // return null;
        })
    } */}
                                    {Array.from(new Set(examFacultyList?.filter(f => f.type === 'chief.examiner')?.map(examFaculty => examFaculty.facultyId)))
                                        .map(facultyId => {
                                            const faculty = facultyList.find(faculty => faculty.id === facultyId);
                                            return (
                                                <option key={facultyId} value={facultyId}>
                                                    {faculty?.name}
                                                </option>
                                            );
                                        })}
                                </select >
                                {editChiefExaminerError && <div className="text-danger">{editChiefExaminerError}</div>}
                            </div >
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setEditModalShow(false)}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={singleEditHandler}>
                            Save
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={showDeleteConfirmation} onHide={closeDeleteConfirmation}>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirm Delete Form</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure you want to delete this item?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={closeDeleteConfirmation}>
                            Cancel
                        </Button>
                        <Button variant="danger" onClick={confirmDeleteHandler}>
                            Delete
                        </Button>
                    </Modal.Footer>
                </Modal>
            </PageContainer >
        </>
    )
}


interface Discipline {
    id: number;
    name: string;
}


interface Degree {
    id: number;
    name: string;
}

interface Course {
    id: number;
    name: string;
}

interface DisciplineCourse {
    id: number;
    degreeId: string;
    disciplineId: string;
    courseId: number;
    intConversion: number;
    extConversion: number;
}

interface Exam {
    id: number;
    title: string;
}

interface DummyNoItem {
    id: number;
    dummyNumber: string;
    registerNumber: string;
    questionPaperCode: string;
    degreeId: string;
    disciplineId: string;
    disciplineCourseId: string;
    intMark?: string;
    extMark: string;
    intConvertedMark?: number;
}

interface StudentArrearType {
    id?: number;
    disciplineId: number;
    degreeId: number;
    registerNo: string;
    semesterNo: number;
    disciplineCourseId: number;
    attemptCount: number;
    clearedSemester: number;
    status: string;
    createdBy: string;
    createTimestamp: string;
}